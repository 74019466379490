import React from "react";
import _ from "lodash";

const PrivacyPolicy = () => {
    return (
        <section className="common-sec">
            <div className="container">
                <div className="sec-head">
                    <h1 className="sec-title text-uppercase">Privacy Policy</h1>
                </div>
                <div className="cms-con">
                    <p>
                        In this Privacy Policy, Visit Social Art  may be referred to as ‘US’, ‘OUR’
                        or ‘WE’.
                    </p>
                    <p>
                        <strong>1.</strong> <strong>Your Personal  Information</strong>
                    </p>
                    <p>
                        As the user of the website or facility and  the purchaser of any of OUR
                        goods and services, you agree and consent that  WE may collect your contact
                        information (your name, email, telephone  number, date of birth, and other
                        contact detail) to complete session bookings  and as a means for US to
                        communicate with you from time to time OUR goods  and services and related
                        matters.
                    </p>
                    <p>
                        Please note that WE do not share or use  this information with outside
                        parties except to the extent required by law and  when we engage third-party
                        service providers to provide you with related  services that WE offer to you
                        through our website, to conduct quality  assurance testing, to provide
                        technical support or to provide specific services  to you.  These
                        third-party service providers are required to not use your  personal
                        information other than to provide the services requested by US.
                    </p>
                    <p>
                        <strong>2. Your Email Address</strong>
                    </p>
                    <p>
                        WE collect email addresses from the person  making session bookings,
                        requesting information and who signs up to receive OUR  newsletter,
                        promotional and advertising material.  The email addresses  WE collect are
                        stored in a secure area within the site database and may be  used for
                        marketing purposes and developing promotional offers and sale events.
                    </p>
                    <p>
                        <strong>3. Your Credit Card Information</strong>
                    </p>
                    <p>
                        OUR website is a secure site and all  credit cards are processed by a secure
                        bank-approved payment gateway.  Your  credit card payments are processed
                        securely.
                    </p>
                    <p>
                        When you book through our website, WE do  not keep your credit card details
                        and they are only passed to the bank for  processing a credit card payment.
                    </p>
                    <p>
                        When you book over the telephone, WE use  your credit card details provided
                        to us to process your credit card payment and  then securely discard the
                        information.
                    </p>
                    <p>
                        When booking private events, WE retain your  credit card details provided to
                        us until after your event and all payments are  made by you. WE then
                        securely discard the information.
                    </p>
                    <p>
                        <strong>4. Photographs and Videography took of  you by US</strong>
                    </p>
                    <p>
                        By registering and attending a session, you  expressly and impliedly
                        authorise and permit US to use any recordings we  make, which may contain
                        images of you, for the promotion and advertising of  OUR business.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default PrivacyPolicy;
