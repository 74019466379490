import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import { Toaster } from "react-hot-toast";

const Wrapper = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="wrapper">
      <Header />
      <Outlet />
      <Footer />
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Wrapper;
