export const formatPhoneNumber = (phone) => {
  const base = String(phone || "").replace(/[^0-9]/g, "");
  let formatted = "";

  const len = base.length;
  if (len > 0) {
    const x = base.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    formatted = +x[1] + (x[2] ? `-${x[2]}` : "") + (x[3] ? `-${x[3]}` : "");
  }

  return {
    base,
    formatted,
  };
};

export const formatCurrency = (amount) => {
  const formatAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }).format(amount);

  return formatAmount;
};

export const formatCardNumber = (value) => {
  const base = String(value || "").replace(/[^0-9]/g, "");
  const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
  const onlyNumbers = base.replace(/[^\d]/g, "");
  let formatted = onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
    [$1, $2, $3, $4].filter((group) => !!group).join(" ")
  );
  return {
    base,
    formatted,
  };
};

export const formatCardExpire = (value = "") => {
  const v = value
    .replace(
      /^([1-9]\/|[2-9])$/g,
      "0$1/" // 3 > 03/
    )
    .replace(
      /^(0[1-9]|1[0-2])$/g,
      "$1/" // 11 > 11/
    )
    .replace(
      /^([0-1])([3-9])$/g,
      "0$1/$2" // 13 > 01/3
    )
    .replace(
      /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
      "$1/$2" // 141 > 01/41
    )
    .replace(
      /^([0]+)\/|[0]+$/g,
      "0" // 0/ > 0 and 00 > 0
    )
    .replace(
      /[^\d\/]|^[\/]*$/g,
      "" // To allow only digits and `/`
    )
    .replace(
      /\/\//g,
      "/" // Prevent entering more than 1 `/`
    );
  return v;
};
