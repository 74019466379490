import React from "react";
import _ from "lodash";

const TermsConditions = () => {
    return (
        <section className="common-sec">
            <div className="container">
                <div className="sec-head">
                    <h1 className="sec-title text-uppercase">Terms and Conditions</h1>
                </div>
                <div className="cms-con">
                    <p>
                        <strong>Acceptance</strong>
                    </p>
                    <p>
                        The website is operated by Visit Social  Art, Social Art &amp; The Social
                        Square. Your use of the website is conditional  upon your acceptance and
                        compliance with the terms, conditions, notices, and  disclaimers set out
                        below (“Terms and Conditions”). You should read them now.  Your use of the
                        website constitutes your agreement to the Terms and Conditions.
                    </p>
                    <p>
                        Social Art reserves the right to amend the  Terms and Conditions at any time
                        and without notice to you. Your continued use  of the website after any
                        amendment becomes effective constitutes an agreement  by you to abide and be
                        bound by the Terms and Conditions, as so amended.
                    </p>
                    <p>
                        <strong>Order</strong>
                    </p>
                    <p>
                        Any order placed by you in the manner  described in this website is an offer
                        by you to purchase a particular product  for the price (including the
                        delivery and other charges and taxes) specified in  this website at the time
                        you place your order on these Terms and Conditions.  Social Art reserves the
                        right to accept or reject your offer for any reason,  including, without
                        limitation, the unavailability of any product, an error in  the price or the
                        product description posted on this website or an error in your  order. Your
                        contract with us only comes into existence when it forwards  confirmation of
                        receipt of your order and payment.
                    </p>
                    <p>
                        <strong>Ticket Prices</strong>
                    </p>
                    <p>
                        Ticket prices of products, services,  delivery and other charges displayed
                        on this website are current at the time of  issue but may change at any time
                        and are subject to availability.
                    </p>
                    <p>
                        ‍ <strong>Taxes</strong>
                    </p>
                    <p>
                        Ticket prices displayed on this website are  non-inclusive of GST. Social
                        Art will retain the title to the products you  order until you have made
                        payment in full for those products/services. All  risks in the products will
                        pass to you upon their delivery to or collection by  a postal or courier
                        service. You should consider whether to obtain any suitable  insurance.
                    </p>
                    <p>
                        <strong>Limitation of Liability</strong>
                    </p>
                    <p>
                        Social Art will in no way be liable for any  direct, indirect, incidental,
                        special or consequential damages, resulting from  use or inability to use
                        the website or for the cost of procurement of  substitute services/products
                        or resulting from any services/products purchased  or obtained or messages
                        received or transactions entered into through the  website or resulting from
                        unauthorised access to or alteration of your  transmissions or data or of
                        any information contained on this website,  including but not limited to,
                        damages for loss of profits, use, data or other  intangibles, even if we
                        have been advised of the possibility of such damages  where any Act of
                        Parliament implies in the Terms and Conditions any term,  condition or
                        warranty, and that Act avoids or prohibits provisions in a  contract
                        excluding or modifying the application of or exercise of, or liability
                        under such term, condition or warranty, such term, condition or warranty
                        shall  be deemed to be included in the Terms and Conditions, however, the
                        liability  for us on any breach of such term, condition or warranty shall be
                        limited, at  our option, to any one or more of the following: if the breach
                        relates to goods  or services:
                    </p>
                    <p>
                        the replacement of the goods/services or  supply of equivalent
                        goods/services;
                    </p>
                    <p>
                        the payment of the cost of replacing the  goods/services or of acquiring
                        equivalent goods; or
                    </p>
                    <p>
                        the payment of the cost of having the goods  repaired; and
                    </p>
                    <p>
                        if the breach relates to services:
                    </p>
                    <p>
                        the supplying of the services again; or
                    </p>
                    <p>
                        the payment of the cost of having the  services supplied again.
                    </p>
                    <p>
                        <strong></strong>
                    </p>
                    <p>
                        <strong>Licence</strong>
                    </p>
                    <p>
                        By virtue of these Terms and Conditions,  you are granted a non-exclusive,
                        non-transferable, non-sub-licensable Licence  to personally access and use
                        for non-commercial purposes the Social Art website/app.  All other uses are
                        prohibited without our prior written consent.
                    </p>
                    <p>
                        This Licence shall continue until  terminated in accordance with this
                        agreement.
                    </p>
                    <p>
                        <strong>Eligibility</strong>
                    </p>
                    <p>
                        Without limitation, Social Art is available  only to individuals with whom
                        only legally binding contracts can be formed  under Australian law. We have
                        the right to refuse to deal with you at any time.  This includes the
                        suspension or termination of your subscription.
                    </p>
                    <p>
                        You agree to provide accurate and truthful  details about yourself for the
                        purposes of your subscription and for the  program and we reserve the right
                        to suspend or terminate your subscription if  we discover you have provided
                        inaccurate, incomplete or misleading personal  information at any time.
                    </p>
                    <p>
                        <strong>Third parties</strong>
                    </p>
                    <p>
                        We may use third parties to assist us to  provide our services to you
                        including payment processing and client  registrations. These third parties
                        may have access to certain personal  information required to perform their
                        function, however, they cannot use your  information for any other purpose.
                    </p>
                    <p>
                        <strong>Renewal &amp; Termination</strong>
                    </p>
                    <p>
                        Your subscription will automatically renew  for the same term as your
                        initial subscription unless you opt out within 24  hours prior to your next
                        scheduled payment. You may cancel your subscription  (you will not receive a
                        refund if you cancel) to the Social Art app by  notifying us through email
                        and we will deactivate any account in your name.
                    </p>
                    <p>
                        <strong>Availability</strong>
                    </p>
                    <p>
                        Social Art is not responsible for any  delays or interruptions to the Social
                        Art website/app. We will always use  reasonable efforts to minimise delays
                        and interruptions however we cannot  guarantee that the website/app will be
                        available at all times or at any given  time.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default TermsConditions;
