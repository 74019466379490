import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { toast } from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Fade } from "react-reveal";
import * as yup from "yup";
import Slider from "react-slick";
import videoWavesImg from "../../assets/img/review-abstact-new.svg";
import reviewSlide1 from "../../assets/img/review-slide-1.jpg";
import placeholderImg from "../../assets/img/event-placeholder.jpg";
import uploadImg from "../../assets/img/upload-icon.svg";
import API from "../../api";
import routes from "../../constants/routesConstants";

const validationSchema = yup.object().shape({
  name: yup.string().max(255).required("Name is required."),
  email: yup
    .string()
    .email("Please enter valid Email.")
    .max(255)
    .required("Email is required."),
  rating: yup.string().required("Rating is required."),
  message: yup.string().required("Description is required."),
});
const WriteReview = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [bookingDetails, setBookingDetails] = useState([]);

  const bookingId = searchParams.get("id")
    ? atob(searchParams.get("id"))
    : null;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (bookingId !== null) {
      getBookingById();
    } else {
      navigate(routes.Events);
    }
  }, [bookingId]);

  const getBookingById = async () => {
    try {
      const response = await API.post("events/booking/viewBookingById", {
        event_booking_id: bookingId,
      });
      if (response?.status) {
        setBookingDetails(response?.data);
        setValue("name", response?.data?.name);
        setValue("email", response?.data?.email);
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (formValue) => {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append("name", formValue.name);
      formData.append("email", formValue.email);
      formData.append("rating", formValue.rating);
      formData.append("description", formValue.message);
      formData.append("booking_id", bookingId);
      let files = [];
      formValue.image.forEach((file) => {
        files.push(file.file);
      });
      for (var i = 0; i < files.length; i++) {
        formData.append("image[]", files[i]);
      }
      const response = await API.post("addreview", formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      if (response?.status) {
        setLoading(false);
        reset();
        navigate(routes.ThankYou);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "image",
  });

  const getThumbnail = (file) => {
    return new Promise((resolve, reject) => {
      if (file.type.match("video")) {
        var video = document.createElement("video");
        var timeupdate = function () {
          if (snapImage()) {
            video.removeEventListener("timeupdate", timeupdate);
            video.pause();
          }
        };
        video.addEventListener("loadeddata", function () {
          if (snapImage()) {
            video.removeEventListener("timeupdate", timeupdate);
          }
        });
        var snapImage = function () {
          var canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas
            .getContext("2d")
            .drawImage(video, 0, 0, canvas.width, canvas.height);
          var image = canvas.toDataURL();
          var success = image.length > 100000;
          if (success) {
            URL.revokeObjectURL(URL.createObjectURL(file));
            resolve(image);
          }
          return success;
        };
        video.addEventListener("timeupdate", timeupdate);
        video.preload = "metadata";
        video.src = URL.createObjectURL(file);
        video.muted = true;
        video.playsInline = true;
        video.currentTime = 1;
        video.play();
      } else {
        resolve(URL.createObjectURL(file));
      }
    });
  };

  const { getInputProps, getRootProps } = useDropzone({
    accept: {
      "image/*": [".jpg", ".jpeg", ".png", ".gif"],
      "video/*": [".mp4"],
    },
    onDrop: (files) =>
      files.forEach(async (file) => {
        const previewUrl = await getThumbnail(file);
        append({
          name: file.name,
          preview: previewUrl,
          file: file,
        });
      }),
  });

  return (
    <section className="contact-sec write-review-sec common-sec">
      <div className="container is-full">
        <div className="sec-head">
          <h3 className="sec-title text-uppercase">Write a review</h3>
        </div>
        <div className="row write-review-row flex-lg-row-reverse flex-column-reverse">
          <div className="col-lg-5 col-12">
            <Fade bottom cascade>
              <div className="write-img-list">
                <Slider
                  className="write-slider"
                  {...{
                    dots: true,
                    arrows: false,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 3500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }}>
                  {bookingDetails?.event?.files.length > 0
                    ? bookingDetails?.event?.files.map((_value, j) => (
                        <div className={`img-box`} key={j}>
                          <img
                            src={
                              _value.file_full_path !== undefined
                                ? _value.file_full_path
                                : placeholderImg
                            }
                            alt=""
                          />
                        </div>
                      ))
                    : !loading && (
                        <div className={`img-box`}>
                          <img src={reviewSlide1} alt="" />
                        </div>
                      )}
                </Slider>
              </div>
            </Fade>
          </div>
          <div className="col-lg-7 col-12">
            <div className="contact-form ms-0 me-0 me-lg-3">
              <div className="contact-inr">
                <Fade bottom cascade>
                  <div className="sec-head">
                    <h2 className="sec-title">
                      Add review for {bookingDetails?.event?.name}?
                    </h2>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row justify-content-center">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            {...register("name")}
                            placeholder="Enter Your Name"
                          />
                          {errors.name?.message != undefined && (
                            <span className="form-error">
                              {errors.name?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form-label">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            {...register("email")}
                            placeholder="Enter Your Email"
                          />
                          {errors.email?.message != undefined && (
                            <span className="form-error">
                              {errors.email?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="rating-box d-flex align-items-center">
                          <span>Rating</span>
                          <div className="rating">
                            <input
                              type="radio"
                              name="rating"
                              value="5"
                              id="rating-5"
                              {...register("rating")}
                            />
                            <label htmlFor="rating-5"></label>
                            <input
                              type="radio"
                              name="rating"
                              value="4"
                              id="rating-4"
                              {...register("rating")}
                            />
                            <label htmlFor="rating-4"></label>
                            <input
                              type="radio"
                              name="rating"
                              value="3"
                              id="rating-3"
                              {...register("rating")}
                            />
                            <label htmlFor="rating-3"></label>
                            <input
                              type="radio"
                              name="rating"
                              value="2"
                              id="rating-2"
                              {...register("rating")}
                            />
                            <label htmlFor="rating-2"></label>
                            <input
                              type="radio"
                              name="rating"
                              value="1"
                              id="rating-1"
                              {...register("rating")}
                            />
                            <label htmlFor="rating-1"></label>
                          </div>
                        </div>
                        {errors.rating?.message != undefined && (
                          <span className="form-error">
                            {errors.rating?.message}
                          </span>
                        )}
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="message" className="form-label">
                            Description
                          </label>
                          <textarea
                            id="message"
                            className="form-control"
                            {...register("message")}
                            rows="5"
                            placeholder="Write a description"></textarea>
                          {errors.message?.message != undefined && (
                            <span className="form-error">
                              {errors.message?.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="message" className="form-label">
                            Upload a file
                          </label>
                          <div className="upload-box" {...getRootProps()}>
                            <div className="upload-content">
                              <img src={uploadImg} alt="" />
                              <span className="drag-text d-block">
                                Drag & drop files or{" "}
                                <span className="browse-text">Browse</span>
                              </span>
                              <span className="support-formate">
                                Supported formates: JPEG, PNG, GIF, MP4
                              </span>
                              <input {...getInputProps()} />
                            </div>
                          </div>
                        </div>
                      </div>
                      {fields.length > 0 && (
                        <div className="col-12 upload-photos-wrapper">
                          {fields.map((f, index) => (
                            <div key={index} className="upload-photos">
                              <img
                                src={f.preview}
                                alt=""
                                className="img-fluid"
                              />
                              <i
                                className="icon icon-close"
                                onClick={() => remove(index)}></i>
                            </div>
                          ))}
                        </div>
                      )}

                      <div className="col-lg-5">
                        <button
                          type="submit"
                          className="btn btn-orange-pink btn-lg w-100"
                          disabled={loading}>
                          {loading && (
                            <div
                              className="spinner-border spinner-border-sm "
                              role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}{" "}
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </form>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-waves">
        <img src={videoWavesImg} alt="" />
      </div>
    </section>
  );
};

export default WriteReview;
