import React, { useContext, useEffect, useState } from "react";
import flagImg from "../../assets/img/flag.svg";
import pinkLeafImg from "../../assets/img/pink-leaf.svg";
import yellowLeafImg from "../../assets/img/yellow-leaf.svg";
import videoWavesImg from "../../assets/img/video-waves.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { formatPhoneNumber } from "../../utils/formats";
import API from "../../api";
import { toast } from "react-hot-toast";
import DataContext from "../../context/dataContent";
import _ from "lodash";
import routes from "../../constants/routesConstants";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";

const validationSchema = yup.object().shape({
  name: yup.string().max(255).required("Name is required."),
  email: yup
    .string()
    .email("Please enter valid Email.")
    .max(255)
    .required("Email is required."),
  phone: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Phone number is to short.")
    .max(12, "Phone number is to long."),
  enquiry_type: yup.string().required("Enquiry Type is required."),
  how_did_hear: yup.string().required("How Did Hear About Us is required."),
});
const ContactUs = () => {
  const dataCtx = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const phone = watch("phone");
  useEffect(() => {
    setValue("phone", formatPhoneNumber(phone).formatted);
  }, [phone]);

  const onSubmit = async (formValue) => {
    try {
      setLoading(true);
      const response = await API.post("contact-us/save", {
        name: formValue.name,
        phone: formatPhoneNumber(formValue.phone).base,
        email: formValue.email,
        message: formValue.message,
        enquiry_type: formValue.enquiry_type.toString(),
        how_did_hear: formValue.how_did_hear,
      });
      if (response?.status) {
        setLoading(false);
        reset();
        navigate(routes.ThankYou);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="contact-sec common-sec">
      <div className="container is-full">
        <div className="row">
          <div className="col-xl-3">
            <Fade bottom cascade>
              <div className="sec-head">
                <h3 className="sec-title text-uppercase">reach us</h3>
              </div>
            </Fade>
            <div className="contact-details">
              <Fade bottom cascade>
                <div className="contact-info d-flex flex-nowrap">
                  <div className="contact-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i className="icon-call"></i>
                  </div>
                  <div className="contact-con">
                    <span className="contact-detail-title">Phone Number</span>
                    <a
                      href={`tel:${dataCtx.settingsData?.setting?.phone
                        .split(" ")
                        .join("")}`}
                      className="contact-detail-sub-title">
                      {dataCtx.settingsData?.setting?.phone}
                    </a>
                  </div>
                </div>
                <div className="contact-info d-flex flex-nowrap">
                  <div className="contact-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i className="icon-email"></i>
                  </div>
                  <div className="contact-con">
                    <span className="contact-detail-title">Email Address</span>
                    <a
                      href={`mailto:${dataCtx.settingsData?.setting?.email}`}
                      className="contact-detail-sub-title">
                      {dataCtx.settingsData?.setting?.email}
                    </a>
                  </div>
                </div>
                <div className="contact-social-media">
                  <span className="contact-detail-title">Follow us</span>
                  <div className="social-media footer-social mt-2">
                    <a
                      href={dataCtx.settingsData?.setting?.facebook_link}
                      className="fb"
                      target="_blank">
                      <i className="icon-facebook"></i>
                    </a>
                    <a
                      href={dataCtx.settingsData?.setting?.instagram_link}
                      className="insta"
                      target="_blank">
                      <i className="icon-instagram"></i>
                    </a>
                    <a
                      href={dataCtx.settingsData?.setting?.twitter_link}
                      className="twitter"
                      target="_blank">
                      <i className="icon-twitter"></i>
                    </a>
                    <a
                      href={dataCtx.settingsData?.setting?.youtube_link}
                      className="youtube"
                      target="_blank">
                      <i className="icon-youtube"></i>
                    </a>
                    <a
                      href={dataCtx.settingsData?.setting?.tiktok_link}
                      className="tiktok"
                      target="_blank">
                      <i className="icon-tiktok"></i>
                    </a>
                    <a
                      href={dataCtx.settingsData?.setting?.thread_link}
                      className="threads"
                      target="_blank">
                      <i className="icon-instagram-threads"></i>
                    </a>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="contact-form">
              <div className="contact-inr">
                <Fade bottom cascade>
                  <div className="sec-head">
                    <h4 className="sec-title">Contact Us</h4>
                  </div>
                </Fade>
                <Fade bottom cascade>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            {...register("name")}
                            placeholder="Enter Your Name"
                          />
                          {errors.name?.message != undefined && (
                            <span className="form-error">
                              {errors.name?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form-label">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            {...register("email")}
                            placeholder="Enter Your Email"
                          />
                          {errors.email?.message != undefined && (
                            <span className="form-error">
                              {errors.email?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form-label">Phone</label>
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control is-phone"
                              {...register("phone")}
                              placeholder="Enter Phone Number"
                              maxLength={12}
                            />
                            <div className="flag-contact">
                              <img src={flagImg} alt="" />
                            </div>
                          </div>
                          {errors.phone?.message != undefined && (
                            <span className="form-error">
                              {errors.phone?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form-label">Event Type</label>
                          <select
                            className="form-select"
                            {...register("enquiry_type")}>
                            <option value="">Select type</option>
                            {_.map(
                              dataCtx.settingsData?.events,
                              (data, idx) => (
                                <option value={data.id} key={idx}>
                                  {data.name}
                                </option>
                              )
                            )}
                          </select>
                          {errors.enquiry_type?.message != undefined && (
                            <span className="form-error">
                              {errors.enquiry_type?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="message" className="form-label">
                            Message
                          </label>
                          <textarea
                            id="message"
                            className="form-control"
                            {...register("message")}
                            rows="5"
                            placeholder="Enter Your Message"></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group">
                          <label htmlFor="name" className="form-label">
                            How Did You Hear About Us?
                          </label>
                          <select
                            className="form-select"
                            {...register("how_did_hear")}>
                            <option value="">Select Source</option>
                            {_.map(
                              dataCtx.settingsData?.how_did_hear,
                              (data, idx) => (
                                <option value={data} key={idx}>
                                  {data}
                                </option>
                              )
                            )}
                          </select>
                          {errors.how_did_hear?.message != undefined && (
                            <span className="form-error">
                              {errors.how_did_hear?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <button
                          type="submit"
                          className="btn btn-orange-pink btn-lg w-100"
                          disabled={loading}>
                          {loading && (
                            <div
                              className="spinner-border spinner-border-sm "
                              role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}{" "}
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-waves">
        <img src={videoWavesImg} alt="" />
      </div>
      <div className="contact-pink-leaf left-leaf">
        <img src={pinkLeafImg} alt=""></img>
      </div>
      <div className="contact-yellow-leaf right-leaf">
        <img src={yellowLeafImg} alt=""></img>
      </div>
    </section>
  );
};

export default ContactUs;
