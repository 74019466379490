import React from "react";
import pinkLeafImg from "../../assets/img/pink-leaf.svg";
import yellowLeafImg from "../../assets/img/yellow-leaf.svg";
import thankYouImg from "../../assets/img/thank-you.svg";
import { Link } from "react-router-dom";
import routes from "../../constants/routesConstants";

const ThankYou = () => {
  return (
    <section className=" thank-you-sec common-sec">
      <div className="container">
        <div className="thank-you-wrap mb-5">
          <div className="thank-you-img">
            <img src={thankYouImg} alt="" className="mx-auto" />
          </div>
        </div>
        <div className="text-center">
          <Link to={routes.Home} className="btn btn-orange-pink">back to home</Link>
        </div>
      </div>
      <div className="thankyou-pink-leaf left-leaf">
        <img src={pinkLeafImg} alt=""></img>
      </div>
      <div className="thankyou-yellow-leaf right-leaf">
        <img src={yellowLeafImg} alt=""></img>
      </div>
    </section>
  );
};

export default ThankYou;
