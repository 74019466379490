import React, { useEffect, useContext, useState, useRef } from "react";
import logoShapeImg from "../../assets/img/logo-shape-2.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import routes from "../../constants/routesConstants";
import DataContext from "../../context/dataContent";
import CheckoutContext from "../../context/checkoutContext";
import _ from "lodash";
import { useScroll } from "../../hook/useScroll";
import API from "../../api";
import useDebounce from "../../hook/useDebounce";
import { formatCurrency } from "../../utils/formats";
import noEventFoundImg from "../../assets/img/no-event-found.svg";
import loaderImg from "../../assets/img/loader.svg";
import { toast } from "react-hot-toast";

const Header = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const checkoutCtx = useContext(CheckoutContext);
  const dataCtx = useContext(DataContext);
  const location = useLocation();
  const scrollListener = useScroll();
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 0);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(false);

  const getEvents = async () => {
    try {
      setLoading(true);
      let payloadData = {
        search: searchDebouncedValue
      };
      const response = await API.post("events/get", payloadData);
      if (response?.status) {
        setEvents(response?.data?.data);
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
      dataCtx.updateLoading(false);
    }
  };

  useEffect(() => {
    if (search.trim() !== "") {
      getEvents();
    } else {
      setEvents([])
    }
  }, [searchDebouncedValue]);




  useEffect(() => {
    if (isNavbarOpen) {
      toggleNavbar();
    }
    if (
      checkoutCtx.cartDetails?.is_payment_success === true &&
      location.pathname !== "/payment-success"
    ) {
      checkoutCtx.removeCartItem();
    }
  }, [location]);

  const toggleSubMenu = (event) => {
    event.preventDefault();
    let El = event.target.parentNode.nextElementSibling;
    El.classList.toggle("active");
  };

  const toggleNavbar = () => {
    if (!isNavbarOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    document.body.classList.toggle("nav-open");
    setIsNavbarOpen(!isNavbarOpen);
  };

  const openSearch = () => {
    if (!isSearchOpen) {
      // document.body.style.overflow = "hidden";
    } else {
      // document.body.style.overflow = "auto";
      setSearch('');
    }
    document.body.classList.toggle("serchbar-open");
    setIsSearchOpen(!isSearchOpen);
  };
  const openSearchClear = () => {
    setSearch('');
  }
  const handleBodyClick = (event) => {
    if (!inputRef.current.contains(event.target)) {
      inputRef.current.classList.remove("your-class-name");
      setIsSearchOpen(false);
      setSearch('');
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, [isSearchOpen]);



  useEffect(() => {
    setSearch('');
    setEvents([]);
    document.body.classList.remove("serchbar-open");
    setIsSearchOpen(!isSearchOpen);
  }, [location]);

  return (
    <header
      className={`header ${scrollListener.scrollDirection === "up"
        ? "is-down"
        : scrollListener.lastScrollTop > 10
          ? "is-up"
          : ""
        } ${scrollListener.lastScrollTop > 150 ? "is-sticky" : ""}`}>
      <div className="container">
        <div className="row g-0 align-items-center">
          <div className="col-auto col-xl-2">
            {dataCtx.settingsData?.setting?.front_header_logo_full_path && (
              <Link
                to={routes.Home}
                className="header-logo d-inline-block"
                title="Visit Social Arts">
                {scrollListener.lastScrollTop > 150 ? (
                  <img src={logoShapeImg} alt="social-arts-creative-logo" />
                ) : (
                  <img
                    src={
                      dataCtx.settingsData?.setting?.front_header_logo_full_path
                    }
                    alt="social-arts-creative-logo"
                  />
                )}
              </Link>
            )}
          </div>
          <div className="col col-xl-7">
            <nav className="header-nav">
              <button
                type="button"
                className="header-nav-toggle-btn d-xl-none d-inline-block"
                onClick={() => toggleNavbar()}>
                <span></span>
              </button>
              <ul>
                <li className="d-block d-xl-inline-block">
                  <NavLink
                    to={routes.Venues}
                    title="Venues"
                    className="d-block text-uppercase">
                    venues
                    {dataCtx.settingsData?.venues?.length !== 0 && (
                      <i
                        className="icon-arrow-down"
                        onClick={toggleSubMenu}></i>
                    )}
                  </NavLink>
                  {dataCtx.settingsData?.venues?.length !== 0 && (
                    <ul>
                      {_.map(dataCtx.settingsData?.venues, (_value, i) => (
                        <li key={i}>
                          <Link
                            title={_value.name}
                            to={`/venues-category/${_value.id}`}
                            className="d-block text-capitalize">
                            {_value.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
                <li className="d-block d-xl-inline-block">
                  <NavLink
                    to={routes.Events}
                    title="Events"
                    className="d-block text-uppercase">
                    events
                    {dataCtx.settingsData?.events?.length !== 0 && (
                      <i
                        className="icon-arrow-down"
                        onClick={toggleSubMenu}></i>
                    )}
                  </NavLink>
                  {dataCtx.settingsData?.events?.length !== 0 && (
                    <ul>
                      {_.map(dataCtx.settingsData?.events, (_value, i) => (
                        <li key={i}>
                          <Link
                            title={_value.name}
                            // to={`${routes.Events}?category_id=${_value.id}`}
                            to={`/events-category/${_value.slug}`} 
                            className="d-block text-capitalize">
                            {_value.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
                <li className="d-block d-xl-inline-block">
                  <NavLink
                    title="Private Events"
                    to={routes.PrivateEvents}
                    className="d-block text-uppercase">
                    private events
                  </NavLink>
                </li>
                <li className="d-block d-xl-inline-block">
                  <NavLink
                    title="Gift Cards"
                    to={routes.GiftsCards}
                    className="d-block text-uppercase">
                    gift cards
                  </NavLink>
                </li>
                <li className="d-block d-xl-inline-block">
                  <NavLink
                    title="Contact Us"
                    to={routes.ContactUs}
                    className="d-block text-uppercase">
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </nav>
            <div
              className="header-back-drop"
              onClick={() => toggleNavbar()}></div>
          </div>
          <div className="col-auto col-xl-3 text-end">
            <div className="header-icon-wrapper">
              <button type="button" className="serch-button d-sm-none" onClick={openSearch}><i className="icon-search"></i></button>
              <from className="header-search">
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1"> <i className="icon-search"></i> </span>
                  <input
                    ref={inputRef}
                    type="text"
                    className="form-control"
                    placeholder="Search Classes"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    aria-label="Username"
                    aria-describedby="basic-addon1" />
                  {search.trim() !== "" && (
                    <button type="button" className="input-group-text input-group-text-close" onClick={openSearchClear} id="basic-addon1"> <i className="icon-close"></i> </button>
                  )}

                </div>
                <button type="tetx" className="serch-close-button d-sm-none" onClick={openSearch}><i className="icon-close"></i></button>
                {searchDebouncedValue.trim() !== "" && ( 
                  <div className="search-open">
                    <div className="search-open-wrapper">
                      {_.map(events, (_value, i) => (
                        <Link
                          className="search-event-item"
                          to={`/events/${_value.slug}`} key={i}>
                          <div className="search-event-img">
                            <img
                              src={_value.thumb_image_full_path}
                              alt={_value.name}
                            />
                          </div>
                          <div className="search-event-desc">
                            {_value.name && (
                              <h5 className="search-event-title">
                                {_value.name}
                              </h5>
                            )}
                            {_value.price && (
                              <span className="search-event-price">
                                {formatCurrency(_value.price)}
                              </span>
                            )}
                          </div>
                        </Link>
                      ))}
                      {!loading && events.length === 0 && (
                        <div className="search-no-events-found">
                          <img src={noEventFoundImg} alt="no-events-found" />
                        </div>
                      )}
                      {loading && (
                        <div className="search-loading-img">
                          <img src={loaderImg} alt="" />
                        </div>
                      )}

                    </div>
                    <Link className="btn btn-orange-pink search-event-btn" to={routes.Events}>See all events <i class="icon-arrow-right"></i></Link>
                  </div>
                )}
              </from>
              <Link
                title="Buy Tickets"
                to={checkoutCtx.isCartEmpty ? routes.Events : routes.Checkout}
                className="btn btn-orange-pink text-uppercase d-inline-block">
                {/* <span className="d-none d-md-block">buy tickets - {checkoutCtx?.cartDetails?.no_of_persons}</span> */}
                <i className="icon-tickets"></i>
                {checkoutCtx?.cartDetails?.no_of_persons && (
                  <span className="count">{checkoutCtx?.cartDetails?.no_of_persons}</span>
                )}
              </Link>
              <button
                title="Navigation Close"
                type="button"
                className="header-nav-toggle-btn align-middle d-lg-inline-block d-xl-none"
                onClick={() => toggleNavbar()}>
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
