import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../api";
import _ from "lodash";
import DataContext from "../../context/dataContent";
import Loader from "../Loader";
import Fade from "react-reveal/Fade";

const Faq = () => {
  const dataCtx = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  useEffect(() => {
    getHome();
  }, []);

  const getHome = async () => {
    try {
      setLoading(true);
      dataCtx.updateLoading(true);
      const response = await API.post("faq/get");
      if (response?.status) {
        setResponseData(response?.data);
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
      dataCtx.updateLoading(false);
    }
  };

  return dataCtx.isPageLoading ? (
    <Loader />
  ) : (
    <section className="faq-sec common-sec">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-sm-12">
            <Fade bottom cascade>
              <div className="sec-head">
                <h3 className="sec-title">FAQ</h3>
              </div>
            </Fade>
            <Fade bottom cascade>
              {responseData?.length !== 0 ? (
                <div className="faq-accordion">
                  <div className="accordion" id="accordionExample">
                    {_.map(responseData, (_value, i) => (
                      <div className="accordion-item" key={i}>
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#ac_${_value.id}`}
                            aria-expanded="false"
                            aria-controls={`ac_${_value.id}`}>
                            {_value.title}
                          </button>
                        </h2>
                        <div
                          id={`ac_${_value.id}`}
                          className={`accordion-collapse collapse`}
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>{_value.content}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p className="text-center">No FAQs Found!</p>
              )}
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
