import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import routes from "./constants/routesConstants";
import NotFound from "./components/NotFound";
import Wrapper from "./components/Wrapper";
import Loader from "./components/Loader";
import PaymentSuccess from "./components/Checkout/PaymentSuccess";
import ThankYou from "./components/ThankYou";
import ContactUs from "./components/ContactUs";
import PrivateEventsDetails from "./components/PrivateEvent/details";
import EventsCategory from "./components/Events/category";
import EventsCategoryDetails from "./components/Events/categoryDetails";
import GiftCardsTerms from "./components/GiftCards/termsConditions";
import TermsConditions from "./components/TermsConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Faq from "./components/Faq";
import WriteReview from "./components/WriteReview";
import ArtistInquiry from "./components/ArtistInquiry";

const Home = lazy(() => import("./components/Home"));
const Events = lazy(() => import("./components/Events"));
const EventsDetails = lazy(() => import("./components/Events/details"));
const GiftCardsDetails = lazy(() => import("./components/GiftCards/details"));
const GiftCards = lazy(() => import("./components/GiftCards"));
const Checkout = lazy(() => import("./components/Checkout"));
const Venues = lazy(() => import("./components/Venues"));
const VenuesCategory = lazy(() => import("./components/Venues/category"));
const VenuesDetails = lazy(() => import("./components/Venues/details"));
const TeamBuilding = lazy(() => import("./components/TeamBuilding"));
const PrivateEvent = lazy(() => import("./components/PrivateEvent"));




function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Wrapper />}>
          <Route
            path={routes.Home}
            element={
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            }
            exact
          />
          <Route
            path={routes.EventsCategory}
            element={
              <Suspense fallback={<Loader />}>
                <EventsCategory />
              </Suspense>
            }
            exact
          />
          <Route
            path={routes.EventsCategoryDetails}
            element={
              <Suspense fallback={<Loader />}>
                <EventsCategoryDetails />
              </Suspense>
            }
            exact
          />
          <Route
            path={routes.Events}
            element={
              <Suspense fallback={<Loader />}>
                <Events />
              </Suspense>
            }
            exact
          />
          <Route
            path={routes.EventsDetails}
            element={
              <Suspense fallback={<Loader />}>
                <EventsDetails />
              </Suspense>
            }
            exact
          />
          <Route
            path={routes.GiftsCards}
            element={
              <Suspense fallback={<Loader />}>
                <GiftCards />
              </Suspense>
            }
            exact
          />
          <Route
            path={routes.GiftsCardsDetails}
            element={
              <Suspense fallback={<Loader />}>
                <GiftCardsDetails />
              </Suspense>
            }
            exact
          />
          <Route
            path={routes.GiftCardsTerms}
            element={
              <Suspense fallback={<Loader />}>
                <GiftCardsTerms />
              </Suspense>
            }
            exact
          />
          <Route
            path={routes.Checkout}
            element={
              <Suspense fallback={<Loader />}>
                <Checkout />
              </Suspense>
            }
            exact
          />
          <Route
            path={routes.Venues}
            element={
              <Suspense fallback={<Loader />}>
                <Venues />
              </Suspense>
            }
            exact
          />
          <Route
            path={routes.VenuesCategory}
            element={
              <Suspense fallback={<Loader />}>
                <VenuesCategory />
              </Suspense>
            }
            exact
          />
          <Route
            path={routes.VenuesDetails}
            element={
              <Suspense fallback={<Loader />}>
                <VenuesDetails />
              </Suspense>
            }
            exact
          />
          {/* <Route
            path={routes.TeamBuilding}
            element={
              <Suspense fallback={<Loader />}>
                <TeamBuilding />
              </Suspense>
            }
            exact
          /> */}
          <Route
            path={routes.PrivateEvents}
            element={
              <Suspense fallback={<Loader />}>
                <PrivateEvent />
              </Suspense>
            }
            exact
          />
          <Route
            path={routes.PrivateEventsDetails}
            element={
              <Suspense fallback={<Loader />}>
                <PrivateEventsDetails />
              </Suspense>
            }
            exact
          />
          <Route path={routes.ContactUs} element={<ContactUs />} exact />
          <Route
            path={routes.PaymentSuccess}
            element={<PaymentSuccess />}
            exact
          />
          <Route path={routes.ThankYou} element={<ThankYou />} exact />
          <Route
            path={routes.TermsConditions}
            element={<TermsConditions />}
            exact
          />
          <Route
            path={routes.PrivacyPolicy}
            element={<PrivacyPolicy />}
            exact
          />
          <Route path={routes.Faq} element={<Faq />} exact />
          <Route path="*" element={<NotFound />} replace />

          <Route path={routes.WriteReview} element={<WriteReview />} exact />
          <Route path={routes.ArtistInquiry} element={<ArtistInquiry />} exact />


        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
