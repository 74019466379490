import { useState, useEffect } from "react";

export function useScroll() {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrollDirection, setScrollDirection] = useState("down");

  const listener = (e) => {
    let st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
      setScrollDirection("down");
    } else if (st < lastScrollTop) {
      setScrollDirection("up");
    }
    setLastScrollTop(st <= 0 ? 0 : st);
  };

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  });

  return {
    lastScrollTop,
    scrollDirection,
  };
}
