import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

API.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('x-token')}`;
  return config;
});
API.interceptors.response.use(
  (response) => response?.data,
  (error) => {
    if (error?.response?.status === 401 && window.location.pathname !== '/') {
      localStorage.removeItem('userData');
      window.location.replace('/');
      return;
    }
    throw Error(
      error?.response?.data?.message ||
        'Something went wrong, Please try after sometime.'
    );
  }
);
export default API;
