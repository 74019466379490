const routes = {
  Home: "/",
  ContactUs: "/contact-us",
  Events: "/booking",
  EventsCategory: "/events-category",
  EventsCategoryDetails: "/events-category/:id",
  EventsDetails: "/events/:id",
  Venues: "/venues",
  VenuesCategory: "/venues-category/:categoryId",
  VenuesDetails: "/venues/:id",
  GiftsCards: "/gift-cards",
  GiftsCardsDetails: "/gift-cards/:id",
  GiftCardsTerms: "/gift-cards/terms-conditions",
  PrivateEvents: "/private-events",
  PrivateEventsDetails: "/private-events/:id",
  Checkout: "/checkout",
  TeamBuilding: "/team-building",
  PaymentSuccess: "/payment-success",
  ThankYou: "/thank-you",
  TermsConditions: "/terms-conditions",
  PrivacyPolicy: "/privacy-policy",
  Faq: "/faq",
  WriteReview: "/write-a-review",
  ArtistInquiry: "/artist-inquiry",
};
export default routes;
