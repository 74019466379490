import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import routes from "../../constants/routesConstants";
import digitalrooarLogoImg from "../../assets/img/digitalrooar.png";
import paymentImg from "../../assets/img/payment-gateways.svg";
import DataContext from "../../context/dataContent";
import _ from "lodash";

const Footer = () => {
  const dataCtx = useContext(DataContext);
  const location = useLocation();

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, [toggleVisible]);

  return (
    <footer
      className={`footer-sec ${
        location.pathname === "/checkout" ||
        location.pathname === "/booking" ||
        location.pathname.includes("/events/")
          ? "with-bottom-spacing"
          : ""
      }`}>
      <div className="container">
        <div className="footer-main">
          <div className="row">
            <div className="col-lg-3 col-12">
              <div className="footer-box logo-section text-sm-start text-center">
                <div className="footer-logo">
                  <Link to={routes.Home} className="d-inline-block d-sm-block">
                    <img
                      src={
                        dataCtx.settingsData?.setting
                          ?.front_footer_logo_full_path
                      }
                      alt=""
                    />
                  </Link>
                </div>
                <div className="social-media footer-social mt-4">
                  <a
                    href={dataCtx.settingsData?.setting?.facebook_link}
                    className="fb"
                    target="_blank">
                    <i className="icon-facebook"></i>
                  </a>
                  <a
                    href={dataCtx.settingsData?.setting?.instagram_link}
                    className="insta"
                    target="_blank">
                    <i className="icon-instagram"></i>
                  </a>
                  
                  <a
                    href={dataCtx.settingsData?.setting?.youtube_link}
                    className="youtube"
                    target="_blank">
                    <i className="icon-youtube"></i>
                  </a>
                  <a
                    href={dataCtx.settingsData?.setting?.tiktok_link}
                    className="tiktok"
                    target="_blank">
                    <i className="icon icon-tiktok"></i>
                  </a>
                  <a
                    href={dataCtx.settingsData?.setting?.thread_link}
                    className="threads"
                    target="_blank">
                    <i className="icon icon-instagram-threads"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-md-block d-none">
              <div className="row">
                <div className="col-sm-4">
                  <div className="footer-box footer-menu">
                    <div className="footer-title">Helpful Links</div>
                    <div className="footer-con">
                      <ul>
                        <li>
                          <NavLink to={routes.Home} className="text-capitalize">
                            <i className="icon-brush"></i>Home
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={routes.GiftsCards}
                            className="text-capitalize">
                            <i className="icon-brush"></i>Gift card
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={routes.Venues}
                            className="text-capitalize">
                            <i className="icon-brush"></i>Venues
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={routes.ContactUs}
                            className="text-capitalize">
                            <i className="icon-brush"></i>Contact Us
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={routes.ArtistInquiry}
                            className="text-capitalize">
                            <i className="icon-brush"></i>Artist Inquiry
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {dataCtx.settingsData?.events?.length !== 0 && (
                  <div className="col-sm-8">
                    <div className="footer-box footer-menu">
                      <div className="footer-title">Events</div>
                      <div className="footer-con">
                        <ul className="d-flex flex-wrap">
                          {_.map(dataCtx.settingsData?.events, (_value, i) => (
                            <li className="w-50" key={i}>
                                <Link to={`/events-category/${_value.slug}`} 
                                className="d-block text-capitalize">
                                <i className="icon-brush"></i>
                                {_value.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-3 col-12 footer-contact-col d-sm-block d-none">
              <div className="footer-box">
                <div className="footer-title mb-md-4 mb-3">Contact Details</div>
                <div className="footer-con">
                  <p className="footer-contact">
                    <i className="icon-location"></i>
                    {dataCtx.settingsData?.setting?.address}
                  </p>
                  <p className="footer-contact">
                    <i className="icon-call"></i>
                    <a
                      href={`tel:${dataCtx.settingsData?.setting?.phone
                        .split(" ")
                        .join("")}`}
                      className="footer-call">
                      {dataCtx.settingsData?.setting?.phone}
                    </a>
                  </p>
                  <p className="footer-contact mt-lg-4">
                    <i className="icon-email"></i>
                    <a
                      href={`mailto:${dataCtx.settingsData?.setting?.email}`}
                      className="footer-mail">
                      {dataCtx.settingsData?.setting?.email}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="footer-cms-links">
                <Link to={routes.PrivacyPolicy} className="text-capitalize">
                  Privacy Policy
                </Link>
                <Link to={routes.TermsConditions} className="text-capitalize">
                  Terms and Conditions
                </Link>
                <Link to={routes.Faq} className="text-capitalize">
                  FAQs
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <div className="footer-copyright-main d-flex flex-column-reverse flex-sm-row align-items-center justify-content-between">
            <div className="copy-right d-sm-flex d-block align-items-center justify-content-start">
              <p>
                © {new Date().getFullYear()}{" "}
                <Link to={routes.Home}>Visit Social Arts.</Link> All Rights
                Reserved |{" "}
                <span className="d-inline-flex align-items-center">
                  Powered by:
                  <a href="https://www.digitalrooar.com.au/">
                    <img src={digitalrooarLogoImg} alt="" />
                  </a>
                </span>
              </p>
            </div>
            <div className="footer-payment-img d-flex justify-content-center justify-content-sm-end">
              <img src={paymentImg} alt="" />
            </div>
          </div>
        </div>
      </div>
      {visible && (
        <button
          type="button"
          className="scroll-to-top btn btn-orange-pink"
          onClick={() => scrollToTop()}>
          <span></span>
        </button>
      )}
    </footer>
  );
};

export default Footer;
