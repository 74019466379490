import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import loaderImg from "../../assets/img/loader.svg";
import logoShapeImg from "../../assets/img/logo-shape-2.svg";
import noEventFoundImg from "../../assets/img/no-event-found.svg";
import API from "../../api";
import { toast } from "react-hot-toast";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";

import moment from "moment/moment";
import { formatCurrency } from "../../utils/formats";
import _ from "lodash";
import useDebounce from "../../hook/useDebounce";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DataContext from "../../context/dataContent";
import Loader from "../Loader";
import { Fade } from "react-reveal";
import routes from "../../constants/routesConstants";

const Events = () => {

  const dataCtx = useContext(DataContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [reload, setReload] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [eventCategories, setEventCategories] = useState([]);
  const [eventTags, setEventTags] = useState([]);
  const [venues, setVenues] = useState([]);
  const [events, setEvents] = useState([]);
  const [states, setStates] = useState([]);
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 1000);
  // const [categaryEvents, setCategaryEvents] = useState([]);
  const [sortType, setSortType] = useState("default-events");
  const [selectedStateId, setSelectedStateId] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState([]);
  const [selectedTagId, setSelectedTagId] = useState([]);
  const [selectedVenueId, setSelectedVenueId] = useState([]);
  const [isToday, setIsToday] = useState(false);
  const [isTomorrow, setIsTomorrow] = useState(false);
  const [isWeekend, setIsWeekend] = useState(false);
  const [isRange, setIsRange] = useState(false);
  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 0,
    last_page: 0,
    current_page: 0,
  });
  const [currentCategory, setCurrentCategory] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const sortRef = useRef(null);
  const { id } = useParams();

  useEffect(() => {
    dataCtx.updateLoading(true);
  }, []);

  // && selectedCategoryId.length === 0
  useEffect(() => {
    if (id) {
      setSelectedCategoryId(id);
      getEvents(1, false, true);
    } else if (searchParams.get("tag_id")) {
      let ids = searchParams
        .get("tag_id")
        .split(",")
        .map(function (id) {
          return parseInt(id);
        });
      setSelectedTagId(ids);
      getEvents(1, false, true);
    } else if (searchParams.get("venue_id") && selectedVenueId.length === 0) {
      let ids = searchParams
        .get("venue_id")
        .split(",")
        .map(function (id) {
          return parseInt(id);
        });
      setSelectedVenueId(ids);
      getEvents(1, false, true);
    } else {
      getEvents(1);
    }
  }, [
    location,
    searchParams,
    selectedStateId,
    searchDebouncedValue,
    sortType,
    isToday,
    isTomorrow,
    isWeekend,
    selectedDate,
    reload,
  ]);


  const getEvents = async (
    page,
    isPagination = false,
    withSearchParams = false
  ) => {
    try {
      setLoading(true);
      if (!isPagination) {
        setEvents([]);
      }
      let payloadData = {
        page,
        event_category_id: id,
        tag_id: withSearchParams
          ? searchParams.get("tag_id")
          : selectedTagId.toString(),
        state_id: selectedStateId,
        venue_id: withSearchParams
          ? searchParams.get("venue_id")
          : selectedVenueId.toString(),
        search: searchDebouncedValue,
        shorting: sortType,
        person: searchParams.get("person"),
      };
      if (sortType === "nearest-events") {
        payloadData = {
          ...payloadData,
          latitude: dataCtx.latLong?.latitude,
          longitude: dataCtx.latLong?.longitude,
        };
      }
      if (isToday) {
        payloadData = {
          ...payloadData,
          date: moment().format("YYYY-MM-DD"),
        };
      } else if (isTomorrow) {
        payloadData = {
          ...payloadData,
          date: moment().add(1, "days").format("YYYY-MM-DD"),
        };
      } else if (isWeekend) {
        payloadData = {
          ...payloadData,
          date: moment().endOf("week").format("YYYY-MM-DD"),
        };
      } else if (isRange && selectedDate !== null) {
        payloadData = {
          ...payloadData,
          start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        };
      }
      const response = await API.post("events/get", payloadData);
      if (response?.status) {
        if (isPagination) {
          setEvents([...events, ...response?.data?.data]);
        } else {
          setEvents(response?.data?.data);
        }
        setPagination({
          total: response?.data?.total,
          per_page: response?.data?.per_page,
          last_page: response?.data?.last_page,
          current_page: response?.data?.current_page,
        });
        setCurrentCategory(response?.data?.event_categories);
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
      dataCtx.updateLoading(false);
    }
  };

  useEffect(() => {
    const getEventCategories = async () => {
      try {
        const response = await API.post("events/categories/get", {
          all_data: 1,
        });
        if (response?.status) {
          setEventCategories(response?.data);
        }
      } catch (error) {
        toast.error(error?.message);
      } finally {
      }
    };

    const getEventTags = async () => {
      try {
        const response = await API.post("tags/get");
        if (response?.status) {
          setEventTags(response?.data);
        }
      } catch (error) {
        toast.error(error?.message);
      } finally {
      }
    };

    const getVenues = async () => {
      try {
        const response = await API.post("venues/get", { all_data: 1 });
        if (response?.status) {
          setVenues(response?.data);
        }
      } catch (error) {
        toast.error(error?.message);
      } finally {
      }
    };

    const getStates = async () => {
      try {
        const response = await API.post("states/get", { country_id: 13 });
        if (response?.status) {
          setStates(response?.data);
        }
      } catch (error) {
        toast.error(error?.message);
      } finally {
      }
    };

    getEventCategories();
    getEventTags();
    getVenues();
    getStates();
  }, []);

  const onEventCategoryChange = async (e, index) => {
    let rawIds = selectedCategoryId;
    if (e.target.checked) {
      rawIds.push(parseInt(e.target.value));
    } else {
      rawIds.splice(rawIds.indexOf(parseInt(e.target.value)), 1);
    }
    if (rawIds.length === 0) {
      if (searchParams.has("category_id")) {
        searchParams.delete("category_id");
        setSearchParams(searchParams);
      }
    }
    setSelectedCategoryId([...rawIds]);
    getEvents(1);
    window.scrollTo(0, 0);
  };


  const onEventTagChange = async (e, index) => {
    let rawIds = selectedTagId;
    if (e.target.checked) {
      rawIds.push(parseInt(e.target.value));
    } else {
      rawIds.splice(rawIds.indexOf(parseInt(e.target.value)), 1);
    }
    if (rawIds.length === 0) {
      if (searchParams.has("tag_id")) {
        searchParams.delete("tag_id");
        setSearchParams(searchParams);
      }
    }
    setSelectedTagId([...rawIds]);
    getEvents(1);
    window.scrollTo(0, 0);
  };


  const onVenueChange = async (e, index) => {
    let rawIds = selectedVenueId;
    if (e.target.checked) {
      rawIds.push(parseInt(e.target.value));
    } else {
      rawIds.splice(rawIds.indexOf(parseInt(e.target.value)), 1);
    }
    if (rawIds.length === 0) {
      if (searchParams.has("venue_id")) {
        searchParams.delete("venue_id");
        setSearchParams(searchParams);
      }
    }
    setSelectedVenueId([...rawIds]);
    getEvents(1);
    window.scrollTo(0, 0);
  };

  const onStateFChange = async (e) => {
    setSelectedStateId(e.target.value);
    window.scrollTo(0, 0);
  };

  const onSortChange = (e) => {
    setSortType(e.target.value);
  };

  const onRangeViewChange = (e) => {
    setIsRange(!isRange);
    if (!e.target.checked) {
      getEvents(1);
      toggleMobileFilter();
      window.scrollTo(0, 0);
    }
  };

  const onRangeChange = (value) => {
    setSelectedDate(value);
    window.scrollTo(0, 0);
  };

  const resetFilters = () => {
    // setSelectedCategoryId([]);
    setSelectedTagId([]);
    setSelectedVenueId([]);
    setSelectedStateId("");
    setSelectedDate(null);
    setIsToday(false);
    setIsTomorrow(false);
    setIsWeekend(false);
    setIsRange(false);
    setSearch("");
    setReload((v) => v + 1);
    // navigate("");
    if (isFilterOpen) {
      toggleMobileFilter();
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight * 0.6 &&
      pagination.current_page <= pagination.last_page &&
      !loading
    ) {
      getEvents(pagination.current_page + 1, true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading]);

  useEffect(() => {
    if (!dataCtx.isPageLoading && sortRef.current !== null) {
      let selectWidth =
        sortRef.current.options[sortRef.current.selectedIndex].innerHTML;
      sortRef.current.style.width = `${selectWidth.length * 10.8}px`;
    }
  }, [sortType, dataCtx.isPageLoading]);

  const toggleMobileFilter = useCallback(() => {
    document.body.classList.toggle("filter-open");
    setIsFilterOpen(!isFilterOpen);
  }, [isFilterOpen]);

  if (dataCtx.isPageLoading) {
    return <Loader />;
  }

  return (
    <>
      <section className="listing-event-sec common-sec">
        <div className="popular-events-container">
          <div className="listing-event-wrap">
            <div className="row">
              <div className="col-lg-4">
                <div className="FilterGroup ">
                  <button
                    className="btn listing-filter-side-cat d-none d-lg-block"
                    onClick={() => toggleMobileFilter()}>
                    <i className="icon-filter"></i>
                    Filters
                  </button>

                  <div className="listing-event-filter">
                    <div className="filterLogo">
                      {dataCtx.settingsData?.setting?.front_header_logo_full_path && (
                        <Link
                          to={routes.Home}
                          className="header-logo d-inline-block"
                          title="Visit Social Arts">
                          <img
                            src={logoShapeImg}
                            alt="social-arts-creative-logo"
                          />
                        </Link>
                      )}
                    </div>

                    <div className="listing-event-filter-details d-flex align-items-baseline justify-content-between ">
                      <div className="listing-event-filter-title text-uppercase">
                        Filters By
                      </div>
                      {(//selectedCategoryId.length !== 0 ||
                        selectedTagId.length !== 0 ||
                        selectedStateId !== "" ||
                        selectedVenueId.length !== 0 ||
                        isTomorrow ||
                        isToday ||
                        isWeekend ||
                        isRange) && (
                          <button
                            type="button"
                            className="listing-event-clear d-sm-inline-block d-none"
                            onClick={() => resetFilters()}>
                            Clear all
                          </button>
                        )}
                    </div>
                    <button
                      type="button"
                      className="btn btn-light listing-filter-apply-btn "
                      onClick={() => toggleMobileFilter()}>
                      Apply
                    </button>
                    <div className="listing-filter-inner">
                      <button
                        type="button"
                        className="listing-filter-close"
                        onClick={() => toggleMobileFilter()}>
                        <span></span>
                      </button>
                      <div className="city-filter-listing">
                        <div className="form-group">
                          <label htmlFor="name" className="form-label">
                            State
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => onStateFChange(e)}
                            value={selectedStateId}>
                            <option value="">Select State</option>
                            {_.map(states, (state, i) => (
                              <option key={i} value={state.id}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* <div className="listing-event-categories">
                    <label className="form-label">Event Categories</label>
                    <div className="listing-event-list">
                      {_.map(eventCategories, (_value, i) => (
                        <div className="form-check" key={i}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={_value.id}
                            id={`c_${i}`}
                            checked={selectedCategoryId.includes(_value.id)}
                            onChange={(e) => onEventCategoryChange(e, i)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`c_${i}`}>
                            {_value.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div> */}
                      <div className="listing-event-venue">
                        <label className="form-label">Venues</label>
                        <div className="listing-event-list">
                          {_.map(venues, (_value, i) => (
                            <div className="form-check" key={i}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={_value.id}
                                id={`v_${i}`}
                                checked={selectedVenueId.includes(_value.id)}
                                onChange={(e) => onVenueChange(e, i)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`v_${i}`}>
                                {_value.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* <div className="listing-event-categories">
                    <label className="form-label">Event Tags</label>
                    <div className="listing-event-list">
                      {_.map(eventTags, (_value, i) => (
                        <div className="form-check" key={i}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={_value.id}
                            id={`t_${i}`}
                            checked={selectedTagId.includes(_value.id)}
                            onChange={(e) => onEventTagChange(e, i)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`t_${i}`}>
                            {_value.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div> */}


                      <div className="listing-event-date">
                        <label className="form-label">Date</label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="todayEventFilter"
                            checked={isToday}
                            onChange={() => [
                              setIsToday(!isToday),
                              setIsWeekend(false),
                              setIsTomorrow(false),
                              setIsRange(false),
                            ]}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="todayEventFilter">
                            Today
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="tomorrowEventFilter"
                            checked={isTomorrow}
                            onChange={() => [
                              setIsTomorrow(!isTomorrow),
                              setIsWeekend(false),
                              setIsToday(false),
                              setIsRange(false),
                            ]}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="tomorrowEventFilter">
                            Tomorrow
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="weekendEventFilter"
                            checked={isWeekend}
                            onChange={() => [
                              setIsWeekend(!isWeekend),
                              setIsTomorrow(false),
                              setIsToday(false),
                              setIsRange(false),
                            ]}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="weekendEventFilter">
                            This Weekend
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="rangeEventFilter"
                            checked={isRange}
                            onChange={(e) => [
                              onRangeViewChange(e),
                              setIsTomorrow(false),
                              setIsToday(false),
                              setIsWeekend(false),
                            ]}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="rangeEventFilter">
                            Range
                          </label>
                        </div>
                        {isRange && (
                          <DateRangePicker
                            onChange={(e) => onRangeChange(e)}
                            value={selectedDate}
                            minDate={new Date()}
                            format="M-d-y"
                            yearPlaceholder="yyyy"
                            monthPlaceholder="mm"
                            dayPlaceholder="dd"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="filter-back-drop"
                    onClick={() => toggleMobileFilter()}></div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="listing-event-search d-flex align-items-center justify-content-end flex-wrap w-100">
                  <div className="listing-event-count px-4">
                    <p className="mb-0 d-none d-md-block">{pagination.total} Events</p>
                    <div className="sec-head d-block d-md-none mt-4 mb-0">
                      <h3 className="sec-title title-md">{currentCategory?.name}</h3>
                    </div>
                  </div>
                  <div className="listing-search-box">
                    <div className="listing-search-filter-wrap position-relative">
                      <i
                        className={`icon-${search !== "" ? "remove with-value" : "search"
                          } position-absolute top-50`}
                        onClick={() => setSearch("")}></i>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Events"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="listing-m-filter-wrap d-flex align-items-center flex-nowrap">
                    <div className="listing-event-sortlist">
                      <label className="form-label text-uppercase">
                        Sort By:
                      </label>
                      <select
                        className="form-select"
                        onChange={(e) => onSortChange(e)}
                        value={sortType}
                        ref={sortRef}>
                        <option value="default-events">Default Events</option>
                        <option value="nearest-events">Nearest Events</option>
                        <option value="latest-to-oldest">Latest Events</option>
                        <option value="oldest-to-latest">Oldest Events</option>
                        <option value="hight-to-low">High Price</option>
                        <option value="low-to-hight">Low Price</option>
                      </select>
                    </div>
                    <button
                      className="btn listing-filter-side-cat d-block d-lg-none"
                      onClick={() => toggleMobileFilter()}>
                      <i className="icon-filter"></i>
                      Filters
                    </button>
                  </div>
                  {(//selectedCategoryId.length !== 0 ||
                    selectedTagId.length !== 0 ||
                    selectedStateId !== "" ||
                    selectedVenueId.length !== 0 ||
                    isTomorrow ||
                    isToday ||
                    isWeekend ||
                    isRange) && (
                      <button
                        type="button"
                        className="listing-event-clear d-inline-block d-md-none"
                        onClick={() => resetFilters()}>
                        Clear
                      </button>
                    )}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="listing-event-item-wrap px-0">
                  <div className="listing-event-item-cate">
                    <div className="row">
                      {_.map(events, (_value, i) => (
                        <div className="col-xxl-2 col-xl-3 col-md-4 col-6" key={i}>
                          <Fade bottom cascade>
                            <div className="listing-item-box-main">
                              <Link
                                to={`/events/${_value.slug}`}
                                className="event-item-box">
                                <div className="event-item-img">
                                  <img
                                    src={_value.thumb_image_full_path}
                                    alt=""
                                  />
                                </div>
                                <div className="event-item-desc">
                                  {_value.name && (
                                    <h5 className="event-item-title">
                                      {_value.name}
                                    </h5>
                                  )}
                                  {_value.price && (
                                    <span className="event-price">
                                      {formatCurrency(_value.price)}
                                    </span>
                                  )}
                                  {_value.run_regularly !== null &&
                                    _value.run_regularly !== "Off" && (
                                      <div className="event-detail-location text-white">
                                        <i className="icon-calendar"></i>
                                        <p>Runs Regularly</p>
                                      </div>
                                    )}
                                  {_value.suburb !== null &&
                                    _value.suburb !== "" && (
                                      <div className="event-detail-location">
                                        <i className="icon-location"></i>
                                        <p>{_value.suburb}</p>
                                      </div>
                                    )}
                                </div>
                              </Link>
                            </div>
                          </Fade>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {!loading && events.length === 0 && (
                  <div className="no-events-found">
                    <img src={noEventFoundImg} alt="no-events-found" />
                  </div>
                )}
                {loading && (
                  <div className="loading-img">
                    <img src={loaderImg} alt="" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

export default Events;
