import React, { createContext, useCallback, useEffect, useState } from "react";
import API from "../api";
import { toast } from "react-hot-toast";

const DataContext = createContext({
  settingsData: {},
  latLong: {},
  isPageLoading: true,
  updateLoading: () => {},
});

export const DataContextProvider = (props) => {
  const [settingsData, setSettingsData] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [latLong, setLatLong] = useState(true);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLatLong({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
    getSettingsData();
  }, []);

  const getSettingsData = async () => {
    try {
      const response = await API.post("setting");
      if (response?.status) {
        setSettingsData(response?.data);
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
    }
  };

  const updateLoading = (data) => {
    setIsPageLoading(data);
  };

  const contextValue = {
    settingsData: settingsData,
    latLong: latLong,
    isPageLoading: isPageLoading,
    updateLoading: updateLoading,
  };

  return (
    <DataContext.Provider value={contextValue}>
      {props.children}
    </DataContext.Provider>
  );
};

export default DataContext;
