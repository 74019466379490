import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../constants/routesConstants";
import tickRoundImg from "../../assets/img/tick-round.svg";
import pinkLeafImg from "../../assets/img/pink-leaf.svg";
import yellowLeafImg from "../../assets/img/yellow-leaf.svg";
import API from "../../api";
import { toast } from "react-hot-toast";
import { formatCurrency, formatPhoneNumber } from "../../utils/formats";
import moment from "moment";
import CheckoutContext from "../../context/checkoutContext";

const PaymentSuccess = () => {
  const checkoutCtx = useContext(CheckoutContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (checkoutCtx.cartDetails?.is_payment_success === undefined) {
      navigate(routes.Checkout);
    } else {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        'event': 'eec.purchase',
        'ecommerce': {
          'purchase': {
            'actionField': {
              'transaction_id': `${checkoutCtx.cartDetails?.booking_id}`,  
              'revenue': `${checkoutCtx.cartDetails?.paid_amount}`,
              'currency': "AUD",
            }
          }
        }
      });
    }
  }, [checkoutCtx.cartDetails]);

  const onTicketDownload = async () => {
    try {
      setLoading(true);
      let payloadData = {};
      if (checkoutCtx.cartDetails?.is_event) {
        payloadData = {
          ...payloadData,
          event_booking_id: checkoutCtx.cartDetails?.id,
        };
      }
      const response = await API.post(
        "events/booking/ticket-download",
        payloadData
      );
      if (response?.status) {
        window.open(response?.data, "_blank");
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <section className="common-sec">
      <div className="container">
        <div className="booking-success border-gradient">
          <div className="sec-head text-center">
            <img src={tickRoundImg} alt="" />
            <h4 className="sec-title">
              {checkoutCtx.cartDetails?.is_event
                ? "Booking Confirmation"
                : "Congratulations!"}
            </h4>
          </div>
          <div className="booking-con">
            <div className="row align-items-center">
              <div
                className={`${checkoutCtx.cartDetails?.is_event ? "col-sm-8" : "col-sm-12"
                  }`}>
                <p className="booking-success-message">
                  {checkoutCtx.cartDetails?.is_event ? (
                    <>
                      {checkoutCtx.cartDetails?.name} your booking has been
                      Confirmed!
                    </>
                  ) : (
                    <>
                      {checkoutCtx.cartDetails?.name} your giftcard has been
                      succesfully send to your email!
                    </>
                  )}
                </p>
                {checkoutCtx.cartDetails?.is_event ? (
                  <>
                    <div className="sec-head">
                      <h5 className="sec-title">Ticket Details</h5>
                    </div>
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <th scope="row" width="25%">
                            Name
                          </th>
                          <td>{checkoutCtx.cartDetails?.name}</td>
                        </tr>
                        <tr>
                          <th scope="row" width="25%">
                            Email
                          </th>
                          <td>
                            <a
                              href={`mailto:${checkoutCtx.cartDetails?.email}`}>
                              {checkoutCtx.cartDetails?.email}
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" width="25%">
                            Mobile
                          </th>
                          <td>
                            <a
                              href={`tel:${checkoutCtx.cartDetails?.phone
                                ?.split("-")
                                .join("")}`}>
                              {
                                formatPhoneNumber(
                                  checkoutCtx.cartDetails?.phone
                                ).formatted
                              }
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="sec-head">
                      <h5 className="sec-title">Event Details</h5>
                    </div>
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <th scope="row" width="25%">
                            Name
                          </th>
                          <td>{checkoutCtx.cartDetails?.event?.name}</td>
                        </tr>
                        <tr>
                          <th scope="row" width="25%">
                            Location
                          </th>
                          <td>{checkoutCtx.cartDetails?.event?.location}</td>
                        </tr>
                        <tr>
                          <th scope="row" width="25%">
                            Date & Time
                          </th>
                          <td>
                            {moment(checkoutCtx.cartDetails?.date).format(
                              "MMM DD, YYYY"
                            )}
                            {" | "}
                            {moment(
                              checkoutCtx.cartDetails?.time,
                              "h:m:s"
                            ).format("h:m a")}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" width="25%">
                            Organization:
                          </th>
                          <td>
                            {checkoutCtx.cartDetails?.event?.organization_name}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                ) : (
                  <>
                    <div className="sec-head">
                      <h5 className="sec-title">Gift Card Details</h5>
                    </div>
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <th scope="row" width="25%">
                            Sender Name
                          </th>
                          <td>{checkoutCtx.cartDetails?.sender_name}</td>
                        </tr>
                        <tr>
                          <th scope="row" width="25%">
                            Recipient Email
                          </th>
                          <td>
                            <a
                              href={`mailto:${checkoutCtx.cartDetails?.email}`}>
                              {checkoutCtx.cartDetails?.email}
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" width="25%">
                            Recipient Mobile
                          </th>
                          <td>
                            <a
                              href={`tel:${checkoutCtx.cartDetails?.phone
                                ?.split("-")
                                .join("")}`}>
                              {
                                formatPhoneNumber(
                                  checkoutCtx.cartDetails?.phone
                                ).formatted
                              }
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
                <div className="sec-head">
                  <h5 className="sec-title">Payment Details</h5>
                </div>
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th width="25%">Base Price</th>
                      <th>
                        {checkoutCtx.cartDetails?.is_event
                          ? "No. of People"
                          : "Quantity"}
                      </th>
                      {checkoutCtx.cartDetails?.gift_amount && checkoutCtx.cartDetails?.gift_amount !== undefined && <th>Discount</th>}
                      {checkoutCtx.cartDetails?.discount_amt && checkoutCtx.cartDetails?.discount_amt !== null && <th>Discount</th>}
                      <th>Total Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {formatCurrency(
                          checkoutCtx.cartDetails?.is_event
                            ? checkoutCtx.cartDetails?.event_price
                            : checkoutCtx.cartDetails?.amount
                        )}
                      </td>
                      <td>
                        {checkoutCtx.cartDetails?.is_event
                          ? checkoutCtx.cartDetails?.no_of_persons
                          : checkoutCtx.cartDetails?.quantity}
                      </td>
                      {checkoutCtx.cartDetails?.gift_amount && checkoutCtx.cartDetails?.gift_amount !== undefined && <td>{formatCurrency(checkoutCtx.cartDetails?.gift_amount)}</td>}
                      {checkoutCtx.cartDetails?.discount_amt && checkoutCtx.cartDetails?.discount_amt !== null && <td>{formatCurrency(checkoutCtx.cartDetails?.discount_amt)}</td>}
                      <td>
                        {formatCurrency(
                          checkoutCtx.cartDetails?.is_event
                            ? checkoutCtx.cartDetails?.paid_amount
                            : checkoutCtx.cartDetails?.total_amount
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {checkoutCtx.cartDetails?.is_event && (
                <div className="col-sm-4 text-center">
                  <div className="booking-ticket">
                    <h2 className="booking-ticket-date">
                      {moment(checkoutCtx.cartDetails?.date).format("DD")}
                    </h2>
                    <span className="booking-ticket-month text-uppercase">
                      {moment(checkoutCtx.cartDetails?.date).format("MMMM")}{" "}
                    </span>
                    <p
                      className={`booking-ticket-time text-uppercase ${checkoutCtx.cartDetails?.booking_id === null
                        ? "border-bottom-0 pb-0"
                        : ""
                        }`}>
                      {moment(checkoutCtx.cartDetails?.date).format(
                        "MMM DD, YYYY"
                      )}{" "}
                      {moment(checkoutCtx.cartDetails?.time, "h:m:s").format(
                        "h:m a"
                      )}
                    </p>
                    <span className="booking-ticket-label">#Booking ID</span>
                    <h5 className="booking-ticket-id">
                      {checkoutCtx.cartDetails?.booking_id}
                    </h5>
                  </div>
                  <button
                    type="button"
                    className="btn btn-orange-pink btn-lg text-uppercase w-100"
                    onClick={() => onTicketDownload()}
                    disabled={loading}>
                    Print Ticket
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="contact-pink-leaf left-leaf">
        <img src={pinkLeafImg} alt=""></img>
      </div>
      <div className="contact-yellow-leaf right-leaf">
        <img src={yellowLeafImg} alt=""></img>
      </div>
    </section>
  );
};

export default PaymentSuccess;
