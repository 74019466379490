import React from "react";
import pinkLeafImg from "../../assets/img/pink-leaf.svg";
import yellowLeafImg from "../../assets/img/yellow-leaf.svg";
import errorImg from "../../assets/img/error.svg";

const NotFound = () => {
  return (
    <section className="common-sec">
      <div className="container">
        <div className="thank-you-wrap">
          <div className="thank-you-img">
            <img src={errorImg} alt="" className="mx-auto" />
          </div>
        </div>
      </div>
      <div className="notfound-pink-leaf left-leaf">
        <img src={pinkLeafImg} alt=""></img>
      </div>
      <div className="notfound-yellow-leaf right-leaf">
        <img src={yellowLeafImg} alt=""></img>
      </div>
    </section>
  );
};

export default NotFound;
