import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../api";
import parse from "html-react-parser";
import PrivateEventCreate from "../PrivateEvent/create";
import { useParams } from "react-router-dom";
import DataContext from "../../context/dataContent";
import Loader from "../Loader";
import { Fade } from "react-reveal";

const PrivateEventsDetails = () => {
  const dataCtx = useContext(DataContext);
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    getPrivateEvent();
  }, []);

  const getPrivateEvent = async () => {
    try {
      setLoading(true);
      dataCtx.updateLoading(true);
      const response = await API.post(`private-events/view?id=${id}`);
      if (response?.status) {
        setResponseData(response?.data?.event);
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
      dataCtx.updateLoading(false);
    }
  };

  return dataCtx.isPageLoading ? (
    <Loader />
  ) : (
    <>
      <section className="why-team-build-sec common-sec">
        <div className="container">
          <Fade bottom>
            <div className="sec-head">
              <h2 className="sec-title text-uppercase">{responseData?.name}</h2>
            </div>
          </Fade>
          <Fade bottom cascade>
            {responseData?.description !== undefined && (
              <div className="cms-con">
                <p>{parse(responseData?.description)}</p>
              </div>
            )}
          </Fade>
          <div className="why-team-build-img-con">
            <Fade bottom cascade>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-sm-6 col-6 why-team-bulid-item-col">
                  <div className="why-team-bulid-item-box">
                    <div className="why-team-bulid-item-img">
                      <img
                        src={responseData?.image1_full_path}
                        alt="why-team-1"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 col-6 why-team-bulid-item-col">
                  <div className="why-team-bulid-item-box">
                    <div className="why-team-bulid-item-img">
                      <img
                        src={responseData?.image2_full_path}
                        alt="why-team-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 col-6 why-team-bulid-item-col">
                  <div className="why-team-bulid-item-box">
                    <div className="why-team-bulid-item-img">
                      <img
                        src={responseData?.image3_full_path}
                        alt="why-team-3"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 col-6 why-team-bulid-item-col">
                  <div className="why-team-bulid-item-box">
                    <div className="why-team-bulid-item-img">
                      <img
                        src={responseData?.image4_full_path}
                        alt="why-team-4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <PrivateEventCreate />
      {responseData?.include_content &&
        <section className="whats-included-sec common-sec pt-0">
          <div className="container is-full">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <Fade bottom cascade>
                  <div className="whats-included-con">
                    <div className="sec-head text-2">
                      <h2 className="sec-title text-capitalize">What's Included?</h2>
                    </div>
                    {responseData?.include_content !== undefined && responseData?.include_content !== null &&
                      <div className="cms-con">
                        {parse(responseData?.include_content)}
                      </div>
                    }
                  </div>
                </Fade>
              </div>
              <div className="col-lg-6 position-relative">
                <Fade bottom cascade>
                  {responseData?.detail_image_full_path && (
                    <div className="whats-included-img">
                      <img
                        src={responseData?.detail_image_full_path}
                        alt=""
                      />
                    </div>
                  )}
                </Fade>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default PrivateEventsDetails;
