import React, { useContext, useEffect, useState } from "react";
import flagImg from "../../assets/img/flag.svg";
import aboutSocialArtBrushImg from "../../assets/img/about-social-art-brush.png";
import artsSaveLiveBrushImg from "../../assets/img/arts-save-live-brush.png";
import { useNavigate } from "react-router-dom";
import API from "../../api";
import { toast } from "react-hot-toast";
import moment from "moment";
import DatePicker from "react-date-picker";
import TimePicker from "react-time-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { formatPhoneNumber } from "../../utils/formats";
import DataContext from "../../context/dataContent";
import routes from "../../constants/routesConstants";
import { Fade } from "react-reveal";

const validationSchema = yup.object().shape({
  name: yup.string().max(255).required("Name is required."),
  email: yup
    .string()
    .email("Please enter valid Email.")
    .max(255)
    .required("Email is required."),
  phone: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Phone number is to short.")
    .max(12, "Phone number is to long."),
  event_type: yup.string().required("Enquiry Type is required."),
  how_did_hear: yup.string().required("How Did Hear About Us is required."),
  no_of_persons: yup
    .number()
    .min(1)
    .typeError("Please enter numeric value.")
    .required("Please enter no. of people."),
  location: yup.string().required("Location is required."),
});

const PrivateEventCreate = () => {
  const dataCtx = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [selectedEventDate, setSelectedEventDate] = useState(new Date());
  const [selectedEventTime, setSelectedEventTime] = useState("10:30");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const phone = watch("phone");
  useEffect(() => {
    setValue("phone", formatPhoneNumber(phone).formatted);
  }, [phone]);

  const no_of_persons = watch("no_of_persons");
  useEffect(() => {
    if (no_of_persons) {
      setValue("no_of_persons", no_of_persons.replace(/[^0-9]/g, ""));
    }
  }, [no_of_persons]);

  const onSubmit = async (formValue) => {
    try {
      setLoading(true);
      const response = await API.post("private-event-bookings/save", {
        name: formValue.name,
        email: formValue.email,
        phone: formatPhoneNumber(formValue.phone).base,
        no_of_persons: formValue.no_of_persons,
        date: moment(selectedEventDate).format("YYYY-MM-DD"),
        event_time: moment(selectedEventTime, "hh:mm:ss").format("hh:mm:ss"),
        event_type: formValue.event_type,
        message: formValue.message,
        how_did_hear: formValue.how_did_hear,
        location: formValue.location,
      });
      if (response?.status) {
        reset();
        setLoading(false);
        navigate(routes.ThankYou);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="venue-detail-contact-sec common-sec pt-0">
      <div className="container">
        <div className="row justify-content-center">
          <Fade bottom>
            <div className="col-xl-8 col-lg-9">
              <div className="private-event border-gradient">
                <div className="private-event-con">
                  <div className="sec-head">
                    <h5 className="sec-title">Private Event Inquiry</h5>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-xxl-4 col-sm-6">
                        <div className="form-group position-relative">
                          <label className="form-label">Name</label>
                          <input
                            className="form-control"
                            {...register("name")}
                            placeholder="Enter Your Name"
                          />
                          {errors.name?.message != undefined && (
                            <span className="form-error">
                              {errors.name?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xxl-4 col-sm-6">
                        <div className="form-group">
                          <label className="form-label">Email</label>
                          <input
                            className="form-control"
                            {...register("email")}
                            placeholder="Enter Your Email"
                          />
                          {errors.email?.message != undefined && (
                            <span className="form-error">
                              {errors.email?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xxl-4 col-sm-6">
                        <div className="form-group">
                          <label className="form-label">Phone</label>
                          <div className="position-relative">
                            <input
                              className="form-control is-phone"
                              {...register("phone")}
                              placeholder="Enter Phone Number"
                            />
                            <div className="flag-contact">
                              <img src={flagImg} alt="" />
                            </div>
                          </div>
                          {errors.phone?.message != undefined && (
                            <span className="form-error">
                              {errors.phone?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xxl-4 col-sm-6">
                        <div className="form-group">
                          <label className="form-label">
                            No. of Participants
                          </label>
                          <input
                            {...register("no_of_persons")}
                            className="form-control"
                            placeholder="Enter No. of Participants"
                          />
                          {errors.no_of_persons?.message != undefined && (
                            <span className="form-error">
                              {errors.no_of_persons?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xxl-4 col-sm-6">
                        <div className="form-group">
                          <label className="form-label">Event Date</label>
                          <DatePicker
                            onChange={setSelectedEventDate}
                            value={selectedEventDate}
                            format="MM/dd/y"
                            yearPlaceholder="YYYY"
                            monthPlaceholder="MMM"
                            dayPlaceholder="DD"
                            minDate={new Date()}
                            calendarIcon={<i className="icon-calendar"></i>}
                            clearIcon={null}
                          />
                        </div>
                      </div>
                      <div className="col-xxl-4 col-sm-6">
                        <div className="form-group">
                          <label className="form-label">Event Time</label>
                          <TimePicker
                            onChange={setSelectedEventTime}
                            value={selectedEventTime}
                            clearIcon={null}
                          />
                        </div>
                      </div>
                      <div className="col-xxl-4 col-sm-6">
                        <div className="form-group">
                          <label className="form-label">Event Type</label>
                          <select
                            className="form-select"
                            {...register("event_type")}>
                            <option value="">Select Event type</option>
                            {_.map(
                              dataCtx.settingsData?.events,
                              (data, idx) => (
                                <option value={data.id} key={idx}>
                                  {data.name}
                                </option>
                              )
                            )}
                          </select>
                          {errors.event_type?.message != undefined && (
                            <span className="form-error">
                              {errors.event_type?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xxl-4 col-sm-6">
                        <div className="form-group">
                          <label className="form-label">Event Location</label>
                          <input
                            {...register("location")}
                            className="form-control"
                            placeholder="Enter Event Location"
                          />
                          {errors.location?.message != undefined && (
                            <span className="form-error">
                              {errors.location?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="message" className="form-label">
                            Message
                          </label>
                          <textarea
                            id="message"
                            className="form-control"
                            {...register("message")}
                            rows="5"
                            placeholder="Enter Your Message"></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label className="form-label">
                            How Did You Hear About Us?
                          </label>
                          <select
                            className="form-select"
                            {...register("how_did_hear")}>
                            <option value="">Select Source</option>
                            {_.map(
                              dataCtx.settingsData?.how_did_hear,
                              (data, idx) => (
                                <option value={data} key={idx}>
                                  {data}
                                </option>
                              )
                            )}
                          </select>
                          {errors.how_did_hear?.message != undefined && (
                            <span className="form-error">
                              {errors.how_did_hear?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          className="btn btn-orange-pink btn-lg w-100"
                          type="submit"
                          disabled={loading}>
                          {loading && (
                            <div
                              className="spinner-border spinner-border-sm "
                              role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}{" "}
                          Send Request
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className="event-detail-art-bruch-left">
        <img src={artsSaveLiveBrushImg} alt=""></img>
      </div>
      <div className="event-detail-art-bruch-right">
        <img src={aboutSocialArtBrushImg} alt=""></img>
      </div>
    </section>
  );
};

export default React.memo(PrivateEventCreate);
