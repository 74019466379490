import React from "react";
import logoShapeImg from "../../assets/img/logo-shape.svg";
const Loader = () => {
  return (
    <section className={`loader-sec`}>
      <div className="loader-img">
        <img src={logoShapeImg} alt="page-loader" />
      </div>
    </section>
  );
};

export default React.memo(Loader);
