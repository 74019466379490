import React, { useState } from "react";

const CheckoutContext = React.createContext({
  cartDetails: {},
  isCartEmpty: true,
  addCartItem: (data) => {},
  removeCartItem: () => {},
});

const retrieveStoredToken = () => {
  const storedCartDetails = JSON.parse(localStorage.getItem("cartDetails"));
  return {
    storedCartDetails: storedCartDetails,
  };
};

export const CheckoutContextProvider = (props) => {
  const cartDetailsOld = retrieveStoredToken();

  let initialCartDetails;
  if (cartDetailsOld) {
    initialCartDetails = cartDetailsOld.storedCartDetails;
  }

  const [cartDetails, setCartDetails] = useState(initialCartDetails);

  const isCartEmpty = cartDetails?.id === undefined;

  const addCartItemHandler = (data) => {
    setCartDetails(data);
    localStorage.setItem("cartDetails", JSON.stringify(data));
  };

  const removeCartItemHandler = () => {
    setCartDetails();
    localStorage.removeItem("cartDetails");
  };

  const contextValue = {
    cartDetails: cartDetails,
    isCartEmpty: isCartEmpty,
    addCartItem: addCartItemHandler,
    removeCartItem: removeCartItemHandler,
  };

  return (
    <CheckoutContext.Provider value={contextValue}>
      {props.children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutContext;
