import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { CheckoutContextProvider } from "./context/checkoutContext";
import { DataContextProvider } from "./context/dataContent";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "./assets/css/style.css";
import "./assets/css/new-style.css";
import "./assets/css/responsive.css";
import "./assets/css/icons.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CheckoutContextProvider>
    <DataContextProvider>
      <App />
    </DataContextProvider>
  </CheckoutContextProvider>
);
