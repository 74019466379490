import React from "react";
import _ from "lodash";

const GiftCardsTerms = () => {
    return (
        <section className="common-sec">
            <div className="container">
                <div className="sec-head">
                    <h1 className="sec-title text-uppercase">Terms and Conditions</h1>
                </div>
                <div className="cms-con">
                    <p>
                        These terms and  conditions apply to the use of the Social Art websites
                        found at  www.visitsocialart.com <br />
                        <br />
                        These terms and conditions specifically refer to the following products  and
                        services which may be purchased or accessed via our websites:
                    </p>
                    <ul type="disc">
                        <li>
                            Experience       Vouchers, Gift Certificates ('Gift Voucher')/ Gift
                            Cards
                        </li>
                        <ul type="circle">
                            <li>
                                Experience        Voucher – a Social Art voucher for a specific
                                experience.
                            </li>
                            <li>
                                Gift        Certificate ('Gift Voucher')/Card – a Social Art voucher
                                with a dollar        value that can be used as currency to purchase
                                from a Social Art website.
                            </li>
                        </ul>
                        <li>
                            Direct       Ship Items (Social Art Gifts &amp; Hampers, and Movie
                            Tickets)
                        </li>
                        <li>
                            Social       Art Account Credits
                        </li>
                        <ul type="circle">
                            <li>
                                These        are credits applied to Social Art customer accounts
                                which carry a dollar        value that can be used to purchase from
                                a Social Art website.
                            </li>
                        </ul>
                        <li>
                            Social       Art Program points
                        </li>
                        <ul type="circle">
                            <li>
                                Part        of some Corporate programs, points are used as currency
                                to purchase from        the relevant Corporate program website.
                            </li>
                        </ul>
                    </ul>
                    <p>
                        <br />
                        Throughout these terms and conditions, the above  products and services are
                        collectively referred to as 'Social Art Gift(s)'. <br />
                        <br />
                        <strong>Table of Contents:</strong> <br />
                    </p>
                    <ol start="1" type="1">
                        <li>
                            <a
                                href="#anchor1"
                            >
                                Making a Purchase
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor2"
                            >
                                Refunds &amp;       Returns
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor3"
                            >
                                Exchange of Social       Art Gifts
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor4"
                            >
                                Prices
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor5"
                            >
                                Transfer of Social       Art Gifts
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor6"
                            >
                                Lost Gift       Vouchers
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor7"
                            >
                                Expiry &amp;       Extensions: Gift Vouchers
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor8"
                            >
                                Expiry &amp;       Extensions: Social Art Account credits and
                                Program points
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor9"
                            >
                                Gift Voucher       Delivery
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor10"
                            >
                                Delivery of       Direct Ship Items
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor11"
                            >
                                Providers of       Experiences
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor12"
                            >
                                Social Art's       Liability
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor13"
                            >
                                Availability of       Experiences
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor14"
                            >
                                Contact Details       Given to Experience Providers
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor15"
                            >
                                Booking       Cancellations
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor16"
                            >
                                The Weather
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor17"
                            >
                                Complaints or       Problems During your Experience
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor18"
                            >
                                Insurance
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor19"
                            >
                                Descriptions of       Experiences and Gift Products
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor20"
                            >
                                Illustrations on       Our Site
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor21"
                            >
                                Experience       Locations
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor22"
                            >
                                Experience       Session Lengths
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor23"
                            >
                                Restrictions on       Taking Experiences
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor24"
                            >
                                Promotional       Offers
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor25"
                            >
                                Other Information
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor26"
                            >
                                Social Art Legal       Entities
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor27"
                            >
                                Information       Security
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor28"
                            >
                                Competition Terms       &amp; Conditions
                            </a>
                        </li>
                        <li>
                            <a
                                href="#anchor29"
                            >
                                Customer Ratings       and Reviews Terms of Use
                            </a>
                        </li>
                    </ol>
                    <p>
                        <br />
                        <a id="anchor1"></a> <strong>1. Making a Purchase</strong> <br />
                        <br />
                        When you purchase an experience voucher or Gift  Card and/or use a Social
                        Art Gift and tick the box that says 'I understand and  accept the Terms and
                        Conditions', you become legally bound by these terms and  conditions and the
                        <a
                            href="https://help.redballoon.com.au/hc/en-us/articles/202214850-Privacy-Copyright"
                            target="_blank"
                        >
                            Big Red Group Privacy Policy
                        </a>
                        .  Please do not proceed to purchase/use your Social Art Gift until you have
                        read,  understood and accepted these terms and conditions. <br />
                        <br />
                        When you make a purchase on behalf of another  individual (such as
                        purchasing an experience or gift card for them) and you  provide us with
                        their personal information, you warrant that you are authorised  to provide
                        us with that personal information and that we are able to use that  personal
                        information for the purposes as set out in our privacy policy. This  would
                        include, for example, using their email address to undertake delivery of
                        the purchase. <br />
                        <br />
                        If you have any queries, please contact us and we  will be pleased to
                        assist. <br />
                        <br />
                        <strong>Australia</strong> <br />
                        Phone: 1300 87 55 00 <br />
                        +61 2 8074 7316 (outside Australia) <br />
                        E-mail:
                        <a href="mailto:info@redballoon.com.au" target="_blank">
                            info@Social Art.com.au
                        </a>
                        <br />
                        <br />
                        <strong>New Zealand</strong> <br />
                        Phone: 0800 555 023 <br />
                        +61 2 8074 7316 (outside New Zealand) <br />
                        E-mail:
                        <a href="mailto:info@redballoon.co.nz" target="_blank">
                            info@Social Art.co.nz
                        </a>
                        <br />
                        <br />
                        <strong>Please note:</strong> Vouchers  purchased on
                        <a href="http://redballoon.com.au/" target="_blank">
                            Social Art.com.au
                        </a>
                        in Australian  Dollars and gift cards purchased in Australian retailers in
                        Australian Dollars  cannot be redeemed or used to purchase experiences on
                        the New Zealand website,
                        <a href="http://redballoon.com.au/" target="_blank">
                            Social Art.co.nz
                        </a>
                        .  Similarly, vouchers purchased on
                        <a href="http://redballoon.co.nz/" target="_blank">
                            Social Art.co.nz
                        </a>
                        in  New Zealand Dollars and gift cards purchased in New Zealand retailers in
                        New  Zealand Dollars cannot be redeemed or used to purchase experiences on
                        the  Australian website,
                        <a href="http://redballoon.com.au/" target="_blank">
                            Social Art.com.au
                        </a>
                        . <br />
                        <br />
                        <a id="anchor2"></a> <strong>2. Gift Voucher  Refunds</strong> <br />
                        <br />
                        <strong>14 Day Change-of-Mind Refund Policy</strong> <br />
                        <br />
                        If you have purchased an Experience Voucher from Social Art, you can request
                        a  refund of the Experience Voucher within 14 days of the purchase date if
                        you  have a change-of-mind. The refund excludes any delivery or transaction
                        fees  paid at the time of purchase. The change of mind refund excludes
                        Physical  products.
                    </p>
                    <p>
                        <strong>Conditions of  Refund:</strong>
                    </p>
                    <ol start="1" type="1">
                        <li>
                            Change-of-mind       refunds are only permitted for valid vouchers that
                            have not been used,       i.e. where an experience date has not yet been
                            booked on the Social Art       system. Once you have requested a
                            booking, your voucher cannot be       cancelled and is not refundable
                            for a change-of-mind, but may be       subject to a credit if the
                            experience cannot be fulfilled.
                        </li>
                        <li>
                            The       refund will be processed back to the purchaser only.
                        </li>
                        <li>
                            Any Social       Art account credits used for the purchase will be
                            refunded in the same       form of tender.
                        </li>
                        <li>
                            The       Change-of-Mind Policy does not apply to Dollar Gift
                            Certificates, Gift       Cards, E-Gift Cards, Gift Products, or any
                            purchases made through a       rewards platform points system (eg. Redii
                            or other partner platforms).
                        </li>
                        <li>
                            After       the elapse of 14 days from the purchase date, no refunds
                            will be granted       for ‘Change-of-Mind’. Un-used, un-expired vouchers
                            can now be exchanged       free of charge (please refer to conditions of
                            Exchange).
                        </li>
                    </ol>
                    <p>
                        <br />
                        <strong>Guarantees and Warranties required by law</strong> <br />
                        <br />
                        The Change of Mind policy above is in addition to your rights under both
                        Australian and New Zealand Consumer Law in respect of guarantees and
                        warranties  for our products and services which by law cannot be excluded.
                        <br />
                        <br />
                        You should contact Social Art with any issues with a product or experience
                        voucher purchased through Social Art. Upon receiving your query, Social Art
                        will contact the supplier or Experience Provider to endeavour to resolve the
                        issue on your behalf. If your issue cannot be resolved, we will provide you
                        a  remedy in accordance with your statutory consumer guarantee rights and
                        these  terms and conditions. <br />
                        <br />
                        In particular:
                    </p>
                    <ul type="disc">
                        <li>
                            In       respect of products purchased on Social Art (e.g. Gifts &amp;
                            Hampers and       their contents) they must be of acceptable quality,
                            free from defects,       match what was advertised and be reasonably fit
                            for the purpose       considering the prices and description provided to
                            you at the point of       sale. If there is a major failure with the
                            product not fulfilling any of       these criteria then you will be
                            entitled to a refund in accordance with       your statutory consumer
                            guarantee rights so long as the complaint is made       to us within a
                            reasonable period of time.
                        </li>
                        <li>
                            In       respect of services it is the independent Experience Providers
                            who       provides the experience to you following the redemption of an
                            experience       voucher (see section 11). You will be entitled to a
                            refund in accordance       with your statutory consumer guarantee rights
                            if there is a major failure       with the Experience Provider not
                            providing the experience with due skill       and care, not providing
                            the experience within a reasonable period of time       within the
                            validity period (18 months), or with the experience not being       fit
                            for the purpose described at the point of sale.
                        </li>
                        <li>
                            Where       the Consumer Law does not apply to a circumstance beyond the
                            control of       either or both of Social Art or the Experience
                            Provider, Social Art will       provide you with a credit.
                        </li>
                    </ul>
                    <p>
                        Please note that refunds can only be processed back  to the original
                        purchaser. <br />
                        <br />
                        <a id="anchor3"></a> <strong>3. Exchange of Social  Art Gifts</strong>
                        <br />
                        <br />
                        <strong>Experience Vouchers</strong> <br />
                        <br />
                        The purchaser or recipient of an experience voucher, at any time during the
                        validity period, can make a booking, for any experience on the Social Art
                        website. The experience presented on the voucher is only a suggestion from
                        the  purchaser of the voucher. The voucher holder is not limited to the
                        experience  displayed on the voucher. <br />
                        <br />
                        <strong>Conditions of exchange:</strong>
                    </p>
                    <ol start="1" type="1">
                        <li>
                            Change       in experience choice is only permitted for valid vouchers
                            that have not       been used, i.e. where an experience date has not yet
                            been requested on the       Social Art system.
                        </li>
                        <li>
                            Any       experience voucher changed for an alternative experience will
                            maintain the       original expiry date of the voucher.
                        </li>
                        <li>
                            If       the new experience chosen is of lesser value, any remaining
                            balance will       be held on your Social Art account. This amount is
                            subject to the same       expiry date as the original voucher. If you
                            choose an experience of higher       value, you will be required and
                            prompted to pay the difference.
                        </li>
                        <li>
                            The       value of your voucher that becomes available does not include
                            any delivery       or transaction fees paid at time of purchase.
                        </li>
                    </ol>
                    <p>
                        <br />
                        <strong>Gift Certificates</strong> <br />
                        <br />
                        Exchanges for change-of-mind do not apply to Gift Certificates. <br />
                        <br />
                        <strong>Gift Cards &amp; E-Gift Cards</strong> <br />
                        <br />
                        Defaced, mutilated, altered, lost or stolen gift cards will not be replaced,
                        refunded or redeemed. Gift cards are redeemable for any product on the
                        Social  Art website. They are not redeemable for cash and cannot be refunded
                        or  exchanged for change-of-mind. Gift cards cannot be used in conjunction
                        with promotional offers. Gift cards will expire 5 years from the date of
                        issue. If the date of issue is not specified, the expiry date will be  5
                        years after the date of purchase. Will be void and may not be redeemed
                        after expiry date. Cannot be reloaded. Cash will not be given for any unused
                        amount. Purchases exceeding available balance require difference paid by
                        other method.  We reserve the right to change the terms contained in these
                        Terms of Use at any  time. To check balance or expiry date visit
                        <a href="http://redballoon.com.au/giftcards" target="_blank">
                            Social Art.com.au/giftcards
                        </a>
                        <br />
                        <br />
                        Gift cards purchased on or after 18th October 2017 will have an  expiry date
                        of 5 years. <br />
                        <br />
                        <strong>Gifts &amp; Hampers</strong> <br />
                        <br />
                        Once a Social Art Gifts &amp; Hampers product has been purchased and
                        dispatched, it cannot be exchanged for change-of-mind. <br />
                        <br />
                        <a id="anchor4"></a> <strong>4. Prices</strong> <br />
                        <br />
                        We reserve the right to change prices without notice. All prices include
                        Goods and Services Tax (GST) at the current rate of 10% in Australia and 15%
                        in  New Zealand. <br />
                        <br />
                        Social Art charges a transaction fee to recover the costs it incurs for
                        credit  card payments. Social Art charges no fees for payments made using
                        other  accepted payment methods. Fees will not apply when redeeming a gift
                        voucher or  using Social Art credit. In addition, the transaction fee will
                        be capped at a  maximum of $15. <br />
                        <br />
                        Transaction fees vary dependent on the payment method chosen. <br />
                        <br />
                        If transaction fees apply, the transaction fees charged will be clearly
                        displayed to Social Art customers at the time of purchase. <br />
                        <br />
                        Sometimes, despite our best efforts, products may be incorrectly displayed
                        on  our website at a price that is lower than the correct price. Where there
                        is  such a manifest error, we will notify you of the error as soon as
                        possible to  provide you with the option of purchasing the product at the
                        correct price. In  the event that you do not wish to pay the correct price,
                        we reserve our rights  to cancel your order, in which case we will refund
                        you any payments that you  have made to us in respect of that product. <br />
                        <br />
                        <a id="anchor5"></a> <strong>5. Transfer of Social Art Gifts</strong>
                        <br />
                        <br />
                        <strong>Gift Vouchers</strong> <br />
                        <br />
                        All valid and unused Social Art Gift Vouchers are fully transferable. Social
                        Art accepts no responsibility for any stolen or fraudulent Gift Vouchers. It
                        is  the holder's responsibility to ensure the safe keeping of the Gift
                        Voucher(s). <br />
                        <br />
                        Gift vouchers (including experience vouchers, gift  certificates, gift cards
                        and e-gift cards) may not, without the prior written  consent of the Big Red
                        Group, be resold or offered for resale (including via  on-line auction or
                        other unauthorised resale sites) or used for advertising,  promotion or
                        other commercial purposes. If a gift voucher is sold or used in  breach of
                        this condition, the gift voucher may be cancelled without a refund  and
                        holder of the gift voucher will be unable to use the gift voucher. Big Red
                        Group cannot  guarantee the validity of any gift voucher offered for resale
                        on 3 <sup>rd</sup> party websites  such as Viagogo, Facebook, eBay or
                        Gumtree. <br />
                        <br />
                        <strong>Social Art Account Credit and Program Points</strong> <br />
                        <br />
                        These may not be transferred under any circumstances. <br />
                        <br />
                        <strong>Gifts &amp; Hampers</strong> <br />
                        <br />
                        Transfer does not apply to Social Art 'Gifts &amp; Hampers'. <br />
                        <br />
                        <a id="anchor6"></a>
                        <strong>
                            6. Lost Gift Vouchers
                        </strong>
                        <br />
                        <br />
                        If a Social Art Gift Voucher is received and subsequently lost, the
                        recipient may be eligible to have a replacement voucher emailed to them.
                        <br />
                        <br />
                        <strong>
                            Conditions for replacement of lost Social Art Gift Vouchers:
                        </strong>
                        <br />
                    </p>
                    <ol start="1" type="1">
                        <li>
                            The       voucher is still valid and has not yet been exchanged or used.
                        </li>
                        <li>
                            Proof       of purchase is provided; either an order number, purchaser's
                            full id or       purchaser's email address.
                        </li>
                        <li>
                            Social       Art is able to correctly verify you as the original
                            recipient.
                        </li>
                    </ol>
                    <p>
                        <br />
                        Please note it is the voucher recipient's  responsibility to obtain and
                        provide Social Art with all required verification  details. Failure to do so
                        will mean no replacement voucher can be issued. <br />
                        <br />
                        <a id="anchor7"></a>
                        <strong>
                            7. Expiry &amp;  Extensions: Gift Vouchers
                        </strong>
                        <br />
                        <br />
                        <strong>Experience Vouchers</strong> <br />
                    </p>
                    <p>
                        Experience  Vouchers <br />
                        <br />
                        Experience Vouchers will remain
                        <em>
                            valid  for 60 months from the date of p
                        </em>
                        urchase subject to the  following;
                    </p>
                    <p>
                        ·                      If the Experience Voucher is not  booked and taken
                        within 18 months the amount paid for the voucher  will be preserved for a
                        further 42 months but the experience supplier  will not be obliged to offer
                        the experience nor to offer the experience at the  same price.
                    </p>
                    <p>
                        ·                      After 18 months,  if the price of the experience has
                        increased, an additional amount will need to  be paid at the time of
                        booking.
                    </p>
                    <p>
                        ·                      After 18 months,  if the experience is no longer
                        available a Social Art Account  Credit will be provided for the amount paid
                        for the voucher.
                    </p>
                    <p>
                        <br />
                        If your gift voucher is close to or has expired up to a maximum of 90 days
                        past the expiry date, you can request an extension for a maximum of 90 days.
                        This will incur a $30 extension fee paid at the time of extension.
                    </p>
                    <p>
                        <br />
                        If your gift voucher has been expired for greater than  90 days the voucher
                        cannot be extended under any circumstances.  Expired vouchers are
                        non-refundable in whole or in part. After a voucher has  expired, it is no
                        longer valid, and all transaction attempts will be declined.  Any unused
                        value at the date of expiry will become the property of Social  Art. <br />
                        <br />
                        If you are eligible for an extension, please  contact our Customer
                        Experience team by getting in contact
                        <a href="contactsupport" target="_blank">
                            here.
                        </a>
                        Please note an  extension can only be granted once per voucher. <br />
                        <br />
                        Vouchers purchased on or after 18th October  2017 will have an expiry date
                        of 60 months.
                    </p>
                    <p>
                        <br />
                        <strong>Gift Certificates or Gift Cards</strong> <br />
                        <br />
                        Gift Certificates/ Cards must be used within  60 months (5 years) of
                        purchase. <br />
                        <br />
                        <a id="anchor8"></a>
                        <strong>
                            8. Expiry &amp;  Extensions: Social Art Account Credits
                        </strong>
                        <br />
                        <br />
                        Social Art Account Credits carry the same expiry  date as the original
                        Experience Voucher, Gift Certificate or Gift Card. Expired  Social Art
                        Account credits are non-refundable in whole or in part. After credit  has
                        expired, it is no longer valid, and all transaction attempts will be
                        declined. Any unused value at the date of expiry will become the property of
                        Social  Art. <br />
                        <br />
                        Extensions for use of Social Art Account Credits  are not permitted under
                        any circumstances. <br />
                        <br />
                        <a id="anchor9"></a> <strong>9. Gift Voucher  Delivery</strong> <br />
                        <br />
                        It is the responsibility of the purchaser to  correctly address gifts,
                        whether by email, post or courier. Social Art cannot  be held responsible
                        for any damaged, missing or delayed orders where incorrect  or incomplete
                        delivery details (address and, if relevant, appropriate delivery
                        instructions) have been provided. <br />
                        <br />
                        All Gift Vouchers are dispatched from Social Art's  fulfilment partner based
                        in Sydney. <br />
                        <br />
                        In New Zealand - through our fulfilment partner,  Orangebox in Auckland.
                        <br />
                        <br />
                        A redelivery charge will apply (depending on the  delivery method chosen) if
                        the item is lost after receipt, if the recipient is  unavailable for receipt
                        of delivery, if the delivery address is incorrect or if  there is no safe
                        place to leave the item. <br />
                        <br />
                        In some circumstances where delivery has been  attempted but unsuccessful,
                        the item will be taken to the nearest post office. <br />
                        <br />
                        <strong>Gift Voucher Delivery via Email</strong> <br />
                        <br />
                        Social Art is not liable or responsible for  any loss suffered as a result
                        of a Gift Voucher via email being blocked by  filters or firewalls, or where
                        the incorrect email address has been entered by  the purchaser. If the
                        recipient does not receive the email Gift Voucher,  please
                        <a href="contact" target="_blank">
                            contact  us
                        </a>
                        to  send vouchers to an alternative email address. Alternatively, we can
                        change the  delivery method which would incur the normal delivery fee of
                        that service. <br />
                        <br />
                        <strong>Delivery via Standard Post</strong> <br />
                        <br />
                        Australia <br />
                    </p>
                    <ul type="disc">
                        <li>
                            Standard       Post delivery is via Australia Post. Social Art accepts
                            no responsibility       for any damage, loss or delay as a result of
                            using Australia Post's       service. If for any reason your Gift
                            Voucher doesn’t arrive, please       contact us immediately and we can
                            arrange for the Gift Voucher emailed to       you free of charge.
                        </li>
                    </ul>
                    <p>
                        <br />
                        New Zealand <br />
                    </p>
                    <ul type="disc">
                        <li>
                            Standard       Post delivery is via New Zealand Post. Social Art accepts
                            no       responsibility for any damage, loss or delay as a result of
                            using New       Zealand Post's service. If for any reason your Gift
                            Voucher doesn’t       arrive, please contact us immediately and we can
                            arrange for the Gift       Voucher emailed to you free of charge.
                        </li>
                    </ul>
                    <p>
                        <br />
                        <strong>Delivery via Express Post</strong> <br />
                        <br />
                        Australia &amp; New Zealand
                    </p>
                    <ul type="disc">
                        <li>
                            Express Post delivery to physical locations is provided via
                            Australia Post.
                        </li>
                        <li>
                            Social       Art accepts no responsibility for any damage, loss or delay
                            as a result of       using postal services.
                        </li>
                        <li>
                            Social       Art can provide you with an Australia Post Express Post
                            tracking code in       the event you need to locate the whereabouts of
                            your delivery.
                        </li>
                        <li>
                            Express       Post deliveries do not require a signature and will be
                            delivered to the       specified recipient's physical postal address
                            provided at time of       purchase.
                        </li>
                        <li>
                            Physical       postal addresses can only be changed on the same date of
                            purchase during       the existing operating hours of the Social Art
                            Customer Experience       Team, by calling in and speaking with one of
                            our Customer Experience       Consultants. Our support hours can be
                            viewed
                            <a
                                href="contactsupport"
                                target="_blank"
                            >
                                here
                            </a>
                        </li>
                        <li>
                            If       the physical postal address provided at time of purchase is
                            incorrect, a redelivery charge of $15.00 for Express Post will apply for
                            the item to be resent.
                        </li>
                        <li>
                            During       December and January, our Fulfilment Centre observes
                            certain closure dates       where physical orders are unable to be
                            fulfilled. Closure dates are       publicised on our physical product
                            pages prior to purchase.
                        </li>
                    </ul>
                    <p>
                        <br />
                        <a id="anchor10"></a> <strong>10. Delivery of  Direct Ship Items</strong>
                    </p>
                    <p>
                        <br />
                        Direct Ship items refers to Social Art Gift Products, and Movie Tickets.
                    </p>
                    <p>
                        <br />
                        It is the responsibility of the purchaser to correctly address gifts. Social
                        Art cannot be held responsible for any damaged, missing or delayed orders
                        where:
                    </p>
                    <ol start="1" type="1">
                        <li>
                            Incorrect       or incomplete delivery details (address and, if
                            relevant, appropriate       delivery instructions) have been provided.
                        </li>
                        <li>
                            It       has been requested that the item be left unattended
                        </li>
                    </ol>
                    <p>
                        We strongly recommend using a business address for  delivery. Residential
                        addresses may cause missed delivery issues. <br />
                        <br />
                        <strong>Social Art Gifts Products</strong> <br />
                    </p>
                    <ul type="disc">
                        <li>
                            Social       Art 'Gifts &amp; Hampers' products are normally dispatched
                            via Australia       Post E-Parcel. Social Art accepts no responsibility
                            for any damage, loss       or delay as a result of using this service.
                            Couriers deliver between 9am       and 5pm (Mon-Fri) and specific dates
                            and times cannot be guaranteed. All       'Gifts &amp; Hampers'
                            deliveries must be signed for (unless specified to       leave
                            unattended) and we cannot deliver to PO Boxes. A redelivery charge
                            of $16.50 will apply if the recipient is unavailable for receipt of
                            delivery, if the delivery address is incorrect or if there is no safe
                            place to leave the item.
                        </li>
                    </ul>
                    <p>
                        <br />
                        <strong>Movie Tickets</strong> <br />
                    </p>
                    <ul type="disc">
                        <li>
                            Movie       Tickets are delivered via various courier services in both
                            Australia and       New Zealand. Social Art accepts no responsibility
                            for any damage, loss or       delay as a result of using this service.
                            In the event you need to track       the item, we will contact the
                            provider on your behalf and provide you with       the necessary
                            tracking info if available.
                        </li>
                    </ul>
                    <p>
                        <br />
                        <a id="anchor11"></a> <strong>11. Providers of  Experiences</strong> <br />
                        <br />
                        Nothing in these terms (including the exclusions and limitations in this
                        section 11) excludes, restricts or modifies any guarantee, right or remedy
                        that  under law cannot be excluded, modified or restricted such as your
                        statutory  consumer guarantee rights, including under the Australian
                        Consumer Law (for  more information see section 2 and section 13).
                    </p>
                    <p>
                        Subject to such  legislation:
                    </p>
                    <ul type="disc">
                        <li>
                            Gift       Vouchers are sold by Social Art as agent for businesses that
                            provide       lifestyle and recreational services to customers
                            ('Experience Providers').
                        </li>
                        <li>
                            Social       Art provides the Gift Voucher and the contract with Social
                            Art is not       finalised until the experience has been fulfilled by
                            the Experience       Provider.
                        </li>
                        <li>
                            Social       Art is not a provider of experiences and Experience
                            Providers provide       these services directly to customers. Social Art
                            has no responsibility for       any services or experiences provided or
                            not provided to you by the       Experience Provider or any other party.
                        </li>
                        <li>
                            Social       Art makes no warranty or representation regarding the
                            standard of any       service to be provided to you by the Experience
                            Provider.
                        </li>
                        <li>
                            All Social       Art vouchers are issued subject to the terms and
                            conditions of each       Experience Provider and these Social Art terms
                            and conditions.
                        </li>
                        <li>
                            Social       Art does not control the business operations of any
                            Experience Provider.
                        </li>
                        <li>
                            Social       Art make no guarantees or warranties about the services
                            that are provided       to you by the experience providers.
                        </li>
                    </ul>
                    <p>
                        <br />
                        <a id="anchor12"></a> <strong>12. Social Art's  Liability</strong> <br />
                        <br />
                        Nothing in these terms (including the exclusions and limitations in this
                        section 12) excludes, restricts or modifies any guarantee, right or remedy
                        that  under law cannot be excluded, modified or restricted such as your
                        statutory  consumer guarantee rights, including under the Australian
                        Consumer Law (for  more information see section 2 and section 13). <br />
                        <br />
                        Subject to such legislation, Social Art has:
                    </p>
                    <ul type="disc">
                        <li>
                            No       liability for any act, omission or default, whether negligent
                            or otherwise       of any experience provider or third party.
                        </li>
                        <li>
                            No       liability for any loss or damage occasioned by any negligent
                            act or       omission of any experience provider or third party.
                        </li>
                        <li>
                            Where       Social Art's liability cannot be excluded, such liability is
                            limited to       the value of the Gift Voucher purchased.
                        </li>
                        <li>
                            Where       an experience cannot be fulfilled on a temporary basis due
                            to a       circumstance beyond the control of either or both of Social
                            Art or the       Experience Provider, Social Art will provide you with a
                            credit to use once       those circumstances resolve.
                        </li>
                    </ul>
                    <p>
                        <a id="anchor13"></a> <strong>13. Availability of Experiences</strong>
                        <br />
                    </p>
                    <p>
                        All experiences are offered by experience providers  subject to
                        availability. Bookings are recommended to be made at least 3 weeks  in
                        advance. If you would like to book during peak times (such as weekends or
                        holidays), you should book further in advance. Bookings are not finalised
                        until  you have received the email entitled "Social Art booking
                        confirmation". Please be advised that some experiences display  availability
                        dates on the website. These dates are guidelines only and are  subject to
                        change. We suggest that if you plan to purchase a voucher for a date  listed
                        at short notice, you contact us to ensure there is still availability
                        before completing the transaction. <br />
                        <br />
                        All Social Art experiences are subject to removal from our website at any
                        time  without notice.
                    </p>
                    <p>
                        In  the event that you have sought to fulfil your experience by booking and
                        that  experience is removed from the site and the experience provider is no
                        longer  honouring bookings or the experience is otherwise no longer
                        available, you will  be offered a choice of remedy in accordance with your
                        statutory consumer  guarantee rights such as a Social Art Account Credit for
                        the value of  the voucher (which will be given to the holder of the
                        voucher), for an  alternative experience of the same value (if available).
                        In the event no  reasonable alternative is available and the experience is
                        still  within the validity period (18 months from date of purchase). Social
                        Art will  provide or a refund back to the original purchaser only (for more
                        information  on your statutory consumer guarantee rights see section 2). If
                        the voucher  is outside the validity period you will be offered a Social Art
                        Account  Credit for the value of the voucher. The Social Art Account  Credit
                        will have the same expiry date as the original experience voucher. <br />
                    </p>
                    <p>
                        Where you receive a refund, the refund will be  processed back to the
                        purchaser only (we are unable to provide a refund to the  recipient of an
                        experience voucher) for the original amount paid excluding any  delivery and
                        transaction fees. Where the original purchase was made by Social  Art Gift
                        Voucher, Gift Card or Account Credit, the experience voucher will be
                        credited in the original form of tender. <br />
                        <br />
                        Social Art accepts no liability for the removal of experiences from the
                        website  (subject to your statutory consumer guarantee rights, for more
                        information see  section 2). <br />
                        <br />
                        <a id="anchor14"></a>
                        <strong>
                            14. Contact Details  Given to Experience Providers
                        </strong>
                        <br />
                        <br />
                        It is your responsibility to ensure you enter your  email address and other
                        contact details correctly when you complete the  experience booking process
                        on a Social Art website. Failure to do so may result  in loss or incorrect
                        delivery of the email confirmation of your experience. As  the experience
                        provider will be expecting you, you may end up forfeiting your  voucher
                        (subject to the experience provider's terms and conditions). <br />
                        <br />
                        <a id="anchor15"></a> <strong>15. Booking  Cancellations</strong> <br />
                        <br />
                        Our experience providers have their own cancellation policies which you are
                        bound to follow. Please refer to the cancellation terms section on  the
                        product description page for the policy specific to your  chosen experience.
                        In the case of short notice cancellations by you,  you may lose your voucher
                        completely if the experience provider is unable to  fill your space on the
                        day. If an experience provider needs to cancel an  experience for any
                        reason, you will be offered to reschedule to a convenient  alternative, or
                        provided with a credit from the supplier. if the  experience provider is
                        unable to reschedule, please  contact Social Art within 7 days from the date
                        of cancellation to arrange a recredit to the payment  method you used to
                        make  the booking. In  the event of multiple experience cancellations by the
                        Experience Provider or if  the Experience Provider cannot reschedule within
                        a reasonable period of time,  you may be entitled to a remedy in accordance
                        with your statutory consumer  guarantee rights (for more information see
                        section 2). Except as required by  your statutory consumer guarantee rights
                        (for more information see section  2), Social Art will not reimburse any
                        travelling, accommodation or  other expenses incurred by you or any other
                        person in the event of a  cancellation. <br />
                        <br />
                        <br />
                        <a id="anchor16"></a> <strong>16. The Weather</strong> <br />
                        Many experiences are weather dependent. You will have the Experience
                        Provider's  details so you can call to confirm whether adverse weather
                        affects your  proposed experience. We suggest you call on the morning of
                        your experience if  you have any doubt about the weather suitability. If bad
                        weather affects a  booking, you will be offered alternative times to
                        reschedule at no cost to you,  or provided with a credit from the supplier.
                        if the experience  provider is unable to reschedule, please contact Social
                        Art within 7 days  of the cancellation to arrange a recredit to the payment
                        method you used to make the booking. In the event of  multiple cancellations
                        for adverse weather or if the Experience Provider cannot  reschedule within
                        a reasonable period of time, you may be entitled to a remedy  in accordance
                        with your statutory consumer guarantee rights (for more  information see
                        section 2).  <br />
                        <br />
                        <br />
                        <a id="anchor17"></a>
                        <strong>
                            17. Complaints or  Problems During Your Experience
                        </strong>
                        <br />
                        <br />
                        If you have a problem or a complaint on the day of  your experience, please
                        bring this to the experience provider's attention  immediately so that
                        issues may be addressed on the spot, where possible. <br />
                        <br />
                        <a id="anchor18"></a> <strong>18. Insurance</strong> <br />
                        <br />
                        Some of the experiences that we promote as an agent are considered
                        dangerous.  Prior to ordering or participating in any experience you should
                        be aware of the  risks involved. Social Art can provide further information
                        if you are in any  doubt about any experience. Experience providers warrant
                        that they maintain  appropriate public liability insurance for all risks
                        associated with its  business and service provisions. We make no warranty or
                        representation about  their business or services whatsoever (subject to your
                        statutory consumer  guarantee rights, for more information see section 2).
                        The responsibility rests  with you to make independent enquiries with the
                        experience provider or other  third parties as to the need to acquire your
                        own insurance cover. <br />
                        <br />
                        <a id="anchor19"></a>
                        <strong>
                            19. Descriptions of  Experiences and Gift Products
                        </strong>
                        <br />
                        <br />
                        <strong>Experiences</strong> <br />
                        <br />
                        Independent experience providers provide the information about their
                        experiences. We try to ensure that our promotional information is accurate
                        and  up-to-date. However, the content or format of some experiences may
                        change  slightly from time to time. The experience provider (upon
                        confirmation of each  booking) will provide the final details about each
                        experience. The experience  provider will notify you of any subsequent
                        changes. Your participation in each  experience is conditional upon your
                        acceptance of the experience provider's  terms and conditions at the time
                        your booking is confirmed by the experience  provider. <br />
                        <br />
                        <strong>Gift Products</strong> <br />
                        <br />
                        At certain times we may need to change the contents of Social Art 'Gifts
                        &amp;  Hampers', for example where individual items are out of stock or have
                        become  obsolete. We will ensure that any replacement item is of comparable
                        quality and  if this is not possible, you may be entitled to a remedy in
                        accordance with  your statutory consumer guarantee rights (for more
                        information see section 2). <br />
                        <br />
                        <a id="anchor20"></a> <strong>20. Illustrations on  Our Sites</strong>
                        <br />
                        <br />
                        The photographs used on our websites to illustrate  single or multi-location
                        experiences are provided to us by experience providers  and are used as an
                        indication only of the machines, vehicles, craft, venues and  animals you
                        will experience. <br />
                        <br />
                        <a id="anchor21"></a> <strong>21. Experience  Locations</strong> <br />
                        <br />
                        Social Art experience providers are located at many  of Australia and New
                        Zealand's best locations. We are continually adding  locations but
                        occasionally we may need to stop featuring some venues. Where  locations are
                        described as "Australia-wide" or "New  Zealand-wide" the description is
                        indicative only. Some experiences may not  be available in remote areas.
                        <br />
                        <br />
                        <a id="anchor22"></a> <strong>22. Experience  Session Length</strong>
                        <br />
                        <br />
                        Experience session lengths are approximate and are  given by experience
                        providers as a guide to the total time you will be at their  venue. Unless
                        otherwise stated, you will normally take your turn with the  experience
                        provider's other customers and there may be waiting time involved.  Any
                        itinerary provided to you is indicative only and the order of events may
                        change. <br />
                        <br />
                        <a id="anchor23"></a>
                        <strong>
                            23. Restrictions on  Taking Experiences
                        </strong>
                        <br />
                        <br />
                        Certain experiences provided by experience providers are subject to minimum
                        age, weight, health or other restrictions. These restrictions are outlined
                        on  the relevant experience information page and also in the email of your
                        booking confirmation.  Please read this information carefully. It is your
                        responsibility to understand  any restrictions prior to confirming your
                        booking. If you are later forced to  cancel due to restrictions, you may be
                        liable for charges by the provider of  your experience or forfeit the
                        experience all together. It is your  responsibility to adhere to the
                        State-based rules (for Australian customers) or  New Zealand Government
                        rules (for NZ customers) and guidelines relating to  COVID-19. You may be
                        turned away on the day of the experience if you are not  able to produce
                        evidence that you have complied with the State or  Government-based
                        regulations, such as full vaccination or medical exemption.  Failure to
                        adhere to these or other State or Government-based guidelines may result  in
                        forfeiture of monies paid. Please refer to section 15 -  Cancellations.
                        <br />
                        <br />
                        <br />
                        <a id="anchor24"></a> <strong>24. Promotional  Offers</strong> <br />
                        <br />
                        For all Social Art promotional terms and conditions  please click here
                        <a
                            href="article/Promotional-Terms-and-Conditions"
                            target="_blank"
                        >
                            Promotional Terms and Conditions
                        </a>
                        <br />
                    </p>
                    <p>
                        <a id="anchor25"></a> <strong>25. Other  Information</strong> <br />
                        <br />
                        The text and illustrations (including photographs)  used by Social Art are
                        protected by copyright and must not be copied in whole  or in part with the
                        express permission of Social Art. <br />
                        <br />
                        <a id="anchor26"></a> <strong>26. Social Art Legal  Entities</strong>
                        <br />
                        <br />
                        The Social Art Australia and New Zealand websites (
                        <a href="http://www.redballoon.com.au/" target="_blank">
                            www.Social Art.com.au
                        </a>
                        and
                        <a href="http://www.redballoon.co.nz/" target="_blank">
                            www.Social Art.co.nz
                        </a>
                        )  are owned and operated by Social Art Pty Ltd (ABN 13 097 376 442) and
                        Social  Art NZ Ltd (CO. # 1417960 / GST # 86 288 370) herein referred to as
                        "Social  Art". We reserve the right to change these terms and conditions
                        without  notice. <br />
                        <br />
                        <a id="anchor27"></a> <strong>27. Information  security</strong> <br />
                        <br />
                        Notwithstanding the reasonable steps taken to keep  information secure,
                        breaches may occur.  In the event of a security  incident we have in place
                        procedures to promptly investigate the incident and  determine if there has
                        been a data breach involving personal information, and  if so, to assess if
                        it is a breach that would require notification.  If it  is, we will notify
                        affected parties in accordance with Privacy Act  requirements. <br />
                        <br />
                        In the event of a security incident involving  unauthorised access, use or
                        disclosure of personal information where a third  party with whom we share
                        personal information is involved, we will seek to work  cooperatively with
                        them to protect the personal information we have shared with  them. <br />
                        <br />
                        <a id="anchor28"></a>
                        <strong>
                            28. Competition  Terms &amp; Conditions
                        </strong>
                        <br />
                        <br />
                        For all Social Art competition terms and conditions  please
                        <a
                            href="article/Competition-Terms-and-Conditions"
                            target="_blank"
                        >
                            click  here
                        </a>
                        . <br />
                        <br />
                        <a id="anchor29"></a>
                        <strong>
                            29. Customer ratings  and reviews terms of use
                        </strong>
                        <br />
                        <br />
                        We encourage you to leave feedback on your  experience with Social Art and
                        the experiences Social Art offers. <br />
                        <br />
                        All submitted content is subject to these terms of  use. In the event of a
                        conflict between these terms and
                        <a
                            href="https://help.redballoon.com.au/hc/en-us/articles/202214850-Privacy-Copyright"
                            target="_blank"
                        >
                            Social Art’s Privacy Policy
                        </a>
                        ,  these terms will prevail to the extent of the inconsistency. <br />
                        <br />
                        By submitting any content to Social Art, you  represent and warrant that:
                    </p>
                    <ul type="disc">
                        <li>
                            You       are the sole author and owner of the intellectual property
                            rights of the       content of your review, and that the content of your
                            review does not       infringe any third party's copyright, patent,
                            trademark, trade secret or       other proprietary rights or rights of
                            publicity or privacy.
                        </li>
                        <li>
                            For       any content that you submit, you grant Social Art a perpetual,
                            irrevocable, royalty-free, transferable right and license to use, copy,
                            modify, delete in its entirety, adapt, publish, translate, create
                            derivative works from and/or sell and/or distribute such content and/or
                            incorporate such content into any form, medium or technology throughout
                            the world without compensation to you;
                        </li>
                        <li>
                            All       "moral rights" you may have in such content have been
                            voluntarily waived by you (including rights of attribution or integrity)
                            that may subsist in your content and agree you have no objection to the
                            publication, use, modification, deletion or exploitation of your content
                            by Social Art;
                        </li>
                        <li>
                            You       are at least 18 years old if completing a review for an
                            experience that       includes alcohol;
                        </li>
                        <li>
                            You       have not been compensated or granted any consideration by any
                            third party       for your review, and that you are providing your
                            review in good faith and       in the spirit in which the process is
                            intended;
                        </li>
                        <li>
                            Use       of the content you supply does not violate these Terms of Use
                            and will not       cause injury to any person or entity;
                        </li>
                        <li>
                            The       content you provide to Social Art: is not false, inaccurate or
                            misleading; does not violate any law, statute, ordinance or regulation
                            (including, but not limited to, those governing export control, consumer
                            protection, unfair competition, anti-discrimination or false
                            advertising)       does not contain any content which is defamatory,
                            libelous, hateful,       racially or religiously biased or offensive,
                            unlawfully threatening or       unlawfully harassing to any individual,
                            partnership or corporation; does       not contain any computer viruses,
                            worms or other potentially damaging       computer programs or files;
                            and
                        </li>
                        <li>
                            The       content you provide does not contain any of the following
                            types of content       or violates other guidelines: Obscenities,
                            discriminatory language, or       other language not suitable for a
                            public forum; advertisements, “spam”       content, or references to
                            other products, offers, or websites; email       addresses, URLs,
                            twitter handles, phone numbers, physical addresses or other       forms
                            of contact information; critical or spiteful comments about other
                            reviews posted on the page or their authors.
                        </li>
                    </ul>
                    <p>
                        <br />
                        You agree to indemnify and hold Social Art (and  its officers, directors,
                        agents, subsidiaries, joint ventures, employees and  third-party service
                        providers), harmless from all claims, demands, and damages  (direct or
                        indirect) including reasonable legal fees, arising out of a breach  of your
                        representations and warranties set forth above, or your violation of  any
                        law or the rights of a third party. <br />
                        <br />
                        All content that you submit will be used at Social  Art’s sole discretion.
                        Social Art reserves the right to change,  condense or delete any content on
                        Social Art’s website that Social  Art deems, in its sole discretion, to
                        violate the content guidelines or  any other provision of these Terms of
                        Use. Social Art does not  guarantee that you will have any recourse through
                        Social Art to edit  or delete any content you have submitted. You
                        acknowledge that you, not Social  Art, are responsible for the contents of
                        your submission. None of the content  that you submit will be subject to any
                        obligation of confidence on the part  of Social Art, its agents,
                        subsidiaries, affiliates, partners or third  party service providers and
                        their respective directors, officers and employees. <br />
                        <br />
                        By submitting your email address in connection with  your rating and review,
                        you agree that Social Art and its third party  service providers may use
                        your email address to contact you about the status of  your review and other
                        administrative purposes. <br />
                        <br />
                        From time to time Social Art may offer  customers incentives to leave
                        reviews (e.g. discount coupon/entry into prize  draws, etc.). As it is
                        important to Social Art that reviews are  impartial and honest, these
                        incentives will be available to customers  regardless of whether the review
                        is positive or negative.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default GiftCardsTerms;
