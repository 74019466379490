import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import API from "../../api";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import DataContext from "../../context/dataContent";
import Loader from "../Loader";
import { Fade } from "react-reveal";
import flagImg from "../../assets/img/flag.svg";
import aboutSocialArtBrushImg from "../../assets/img/about-social-art-brush.png";
import artsSaveLiveBrushImg from "../../assets/img/arts-save-live-brush.png";

import moment from "moment";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { formatPhoneNumber } from "../../utils/formats";
import routes from "../../constants/routesConstants";


const validationSchema = yup.object().shape({
  name: yup.string().max(255).required("Name is required."),
  email: yup
    .string()
    .email("Please enter valid Email.")
    .max(255)
    .required("Email is required."),
  phone: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Phone number is to short.")
    .max(12, "Phone number is to long."),
  business_name: yup.string().max(255).required("Business Name is required."),
  business_address: yup.string().required("Business Address is required."),
});


const ArtistInquiry = () => {
  const dataCtx = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const phone = watch("phone");
  useEffect(() => {
    setValue("phone", formatPhoneNumber(phone).formatted);
  }, [phone]);


  const onSubmit = async (formValue) => {
    try {
      setLoading(true);
      const response = await API.post("add-artist", {
        contact_person_name: formValue.name,
        email_address: formValue.email,
        phone_number: formatPhoneNumber(formValue.phone).base,
        business_name: formValue.business_name,
        business_address: formValue.business_address,
      });
      if (response?.status) {
        reset();
        setLoading(false);
        navigate(routes.ThankYou);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
       <section className="venue-detail-contact-sec common-sec pt-4">
      <div className="container">
        <div className="row justify-content-center">
          <Fade bottom>
            <div className="col-xl-8 col-lg-9">
              <div className="private-event border-gradient">
                <div className="private-event-con">
                  <div className="sec-head">
                    <h5 className="sec-title">Artist Inquiry</h5>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-xxl-4 col-sm-6">
                          <div className="form-group">
                            <label className="form-label">
                              Business Name 
                            </label>
                            <input
                              {...register("business_name")}
                              className="form-control"
                              placeholder="Enter Your Business Name "
                            />
                            {errors.business_name?.message != undefined && (
                              <span className="form-error">
                                {errors.business_name?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-xxl-4 col-sm-6">
                          <div className="form-group">
                            <label className="form-label">Business Address</label>
                            <input
                              {...register("business_address")}
                              className="form-control"
                              placeholder="Enter Your Business Address"
                            />
                            {errors.business_address?.message != undefined && (
                              <span className="form-error">
                                {errors.business_address?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-xxl-4 col-sm-6"></div>
                      <div className="col-xxl-4 col-sm-6">
                        <div className="form-group position-relative">
                          <label className="form-label">Contact Person Name</label>
                          <input
                            className="form-control"
                            {...register("name")}
                            placeholder="Enter Contact Person Name"
                          />
                          {errors.name?.message != undefined && (
                            <span className="form-error">
                              {errors.name?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xxl-4 col-sm-6">
                        <div className="form-group">
                          <label className="form-label">Email Address </label>
                          <input
                            className="form-control"
                            {...register("email")}
                            placeholder="Enter Your Email"
                          />
                          {errors.email?.message != undefined && (
                            <span className="form-error">
                              {errors.email?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xxl-4 col-sm-6">
                        <div className="form-group">
                          <label className="form-label">Phone Number</label>
                          <div className="position-relative">
                            <input
                              className="form-control is-phone"
                              {...register("phone")}
                              placeholder="Enter Phone Number"
                            />
                            <div className="flag-contact">
                              <img src={flagImg} alt="" />
                            </div>
                          </div>
                          {errors.phone?.message != undefined && (
                            <span className="form-error">
                              {errors.phone?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      
                      <div className="col-12">
                        <button
                          className="btn btn-orange-pink btn-lg w-100"
                          type="submit"
                          disabled={loading}>
                          {loading && (
                            <div
                              className="spinner-border spinner-border-sm "
                              role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}{" "}
                          Send Request
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className="event-detail-art-bruch-left">
        <img src={artsSaveLiveBrushImg} alt=""></img>
      </div>
      <div className="event-detail-art-bruch-right">
        <img src={aboutSocialArtBrushImg} alt=""></img>
      </div>
    </section>
    </>
  );
};

export default ArtistInquiry;
