import React, { useContext, useEffect, useState } from "react";
import pinkLeafImg from "../../assets/img/pink-leaf.svg";
import { toast } from "react-hot-toast";
import API from "../../api";
import { Link } from "react-router-dom";
import _ from "lodash";
import loaderImg from "../../assets/img/loader.svg";
import DataContext from "../../context/dataContent";
import Loader from "../Loader";
import { Fade } from "react-reveal";

const EventsCategory = () => {
  const dataCtx = useContext(DataContext);
  const [eventCategories, setEventCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getEventCategories = async () => {
      setLoading(true);
      try {
        const response = await API.post("events/categories/get", {
          all_data: 1,
        });
        if (response?.status) {
          setEventCategories(response?.data);
        }
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setLoading(false);
        dataCtx.updateLoading(false);
      }
    };

    getEventCategories();
  }, []);

  return dataCtx.isPageLoading ? (
    <Loader />
  ) : (
    <section className="qeesland-sec common-sec">
      <div className="container">
        <Fade bottom cascade>
          <div className="sec-head">
            <h2 className="sec-title text-uppercase">Events</h2>
          </div>
        </Fade>
        <div className="qeesland-con">
          <div className="row">
            {_.map(eventCategories, (_value, j) => (
              <div className="col-xl-3 col-md-4 col-6" key={j}>
                <Fade bottom cascade>
                  <Link
                    to={`/events-category/${_value.slug}`}
                    className="venues-listing-item-box">
                    <div className="venues-listing-item-img">
                      <img src={_value.image_full_path} alt="" />
                    </div>
                    <div className="venues-listing-item-desc">
                      <h5 className="venues-listing-item-title">{_value.name}</h5>
                    </div>
                  </Link>
                </Fade>
              </div>
            ))}
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-img">
          <img src={loaderImg} alt="" />
        </div>
      )}
      <div className="qeesland-pink-leaf">
        <img src={pinkLeafImg} alt="pink-leaf" />
      </div>
    </section>
  );
};

export default EventsCategory;
